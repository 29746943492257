<template>
    <div class="site-struct extended">

        <LoadingStruct is-dark :is-loading="loading">

            <Card is-dark mode="default">

                <InfoBox v-if="!settings.isAnyLicenseActive"
                         mode="danger"
                         icon="warning"
                         class="uk-margin-medium-bottom">

                    <div>
                        <Translate translation-key="no.active.license"/>
                    </div>

                    <div v-if="settings.downloaderActiveUntil">
                        <Translate v-if="isDateStringFuture(settings.downloaderActiveUntil)"
                                   :parameter="{
                                 duration: getDaysLeftByDateString(settings.downloaderActiveUntil, false),
                                 datetime: getFormattedDateTimeByDateString(settings.downloaderActiveUntil),
                                 description: $t('system')+'-',
                               }"
                                   translation-key="downloader.active.until.message"/>

                        <Translate v-else
                                   translation-key="downloader.disabled.message"
                                   :parameter="{
                                 duration: getDaysLeftByDateString(settings.downloaderActiveUntil, false),
                                 datetime: getFormattedDateTimeByDateString(settings.downloaderActiveUntil),
                                 description: $t('system')+'-',
                               }"
                        />
                    </div>

                </InfoBox>


                <InfoBox v-if="settings && settings.smtpConfig && !settings.smtpConfig.valid"
                         mode="danger"
                         icon="warning"
                         class="uk-margin-medium-bottom">

                    {{ $t('smtp.config.invalid.hint') }}

                    <Button mode="success" class="uk-margin-small-left" has-action :action="showSMTPConfig">
                        {{ $t('fix.now') }}
                    </Button>
                </InfoBox>


                <ul uk-tab="animation: uk-animation-fade" id="tabs">
                    <li v-for="(tab, key) in tabs" :key="tab"
                        :class="getTabStatus(key, tab)"
                        v-on:click="setHashtag(tab)">
                        <a href="#">{{ $t(tab) }}</a>
                    </li>
                    <li class="uk-width-expand">
                    </li>

                    <li class="uk-text-right" :uk-tooltip="'title:'+$t('generate.qrcode')">
                        <a :href="generateQRCode()" target="_blank" v-on:click="openQRCodeLink()">
                            <Icon icon="grid"/>
                        </a>
                    </li>
                    <li class="uk-text-right" :uk-tooltip="'title:'+$t('support')">
                        <a :href="supportLink" target="_blank" v-on:click="openSupportLink()">
                            <Icon icon="lifesaver"/>
                        </a>
                    </li>
                    <li class="uk-text-right" :uk-tooltip="'title:'+$t('logout')">
                        <a v-on:click="logout()">
                            <Icon icon="sign-out"/>
                        </a>
                    </li>

                </ul>

                <ul class="uk-switcher uk-margin">
                    <li>
                        <Modal :id="modalIdArticleDelete"
                               :title="$t('x.delete', {x: $t('article')})">
                            <div class="uk-text-center">
                                {{ $t('article.delete.confirm') }}
                            </div>

                            <div class="uk-flex uk-flex-center uk-margin-medium-top" uk-grid>
                                <div>
                                    <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                            is-max is-min-width
                                            mode="default"/>
                                </div>
                                <div>
                                    <Button :action="deleteArticle" :is-loading="loadingDelete"
                                            :text="$t('x.delete', {x: $t('article')})"
                                            has-action is-large
                                            is-max is-min-width mode="danger"/>
                                </div>
                            </div>

                        </Modal>

                        <Modal :id="modalIdArticle" id-title="modal-title"
                               is-extended
                               :title="$t(selectedArticle ? (selectedArticleClone ? 'x.duplicate' : 'x.change') : 'x.add', {x: $t('article')})">

                            <div uk-grid
                                 class="uk-grid uk-grid-divider uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-1">

                                <div>

                                    <LabelWithError :label="$t('type')+'*'"/>
                                    <select v-model="createArticleType" class="uk-select uk-input-styled"
                                            v-on:change="articleChangeHandler()">
                                        <option v-for="option in settings.processingModules" :key="option"
                                                :value="option">
                                            {{ capitalize($t(option)) }}
                                        </option>
                                    </select>
                                    <div class="uk-margin-bottom"/>

                                    <div :class="(createArticleType === 'mirage') ? '' : 'uk-hidden'">
                                        <div class="uk-margin-bottom"/>
                                        <LabelWithError :error="createArticleMirageError"
                                                        :label="$t('mirage.file')+ ' ('+$t('optional')+')'"/>

                                        <div class="uk-hidden" uk-form-custom>
                                            <input id="mirage"
                                                   ref="mirage"
                                                   :accept="'.'+getComputedMirageFileExtensions.join(', .')"
                                                   type="file"
                                                   v-on:change="handleFilesUpload()"
                                                   v-on:click="$refs.mirage.value=null"
                                            />
                                        </div>

                                        <div v-if="createArticleMirage">
                                            <div id="mirage-upload"
                                                 class="js-upload uk-placeholder uk-text-center">

                                                <div class="uk-inline mirage-struct">
                                                    <div class="uk-position-top-right">
                                                        <div class="uk-grid-small" uk-grid>
                                                            <div>
                                                        <span class="cursor-pointer" v-on:click="triggerInputDialog()">
                                                            <Icon :uk-tooltip="'title:'+$t('replace')" icon="upload"/>
                                                        </span>
                                                            </div>
                                                            <div>
                                                        <span class="cursor-pointer" v-on:click="downloadMirage()">
                                                            <Icon :uk-tooltip="'title:'+$t('download')"
                                                                  icon="cloud-download"/>
                                                        </span>
                                                            </div>
                                                            <div>
                                                        <span class="cursor-pointer" v-on:click="deleteMirage()">
                                                            <Icon :uk-tooltip="'title:'+$t('delete')" icon="trash"/>
                                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Icon :ratio="2.2" class="upload-icon" icon="cog"/>
                                                    </div>
                                                    <div class="uk-tex t-middle file-name">
                                                <span v-if="createArticleMirage.name"
                                                      :uk-tooltip="'title:'+getParsedFileName(createArticleMirage.name)">
                                                    {{ getParsedFileName(createArticleMirage.name) }}
                                                </span>
                                                        <span
                                                            :uk-tooltip="'title:'+getParsedFileName(createArticleMirage)"
                                                            v-else>
                                                    {{ getParsedFileName(createArticleMirage) }}
                                                </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div v-else>

                                            <div id="mirage-upload"
                                                 class="js-upload uk-placeholder uk-placeholder-hover uk-text-center cursor-pointer"
                                                 v-on:click="triggerInputDialog()">

                                                <div>
                                                    <Icon :ratio="2.2" class="upload-icon" icon="cloud-upload"/>
                                                </div>

                                                <span class="uk-text-middle">{{
                                                        $t('x.drop.or.', {x: $t('mirage.file')})
                                                    }}</span>
                                                <span class="uk-link uk-text-middle">{{ $t('select') }}</span>

                                                <div v-if="createArticleMirageError" class="uk-margin-top"/>
                                                <LabelWithError v-if="createArticleMirageError"
                                                                :error="createArticleMirageError"
                                                                :label="$t('mirage.file')"/>
                                            </div>

                                        </div>
                                    </div>


                                    <div
                                        :uk-tooltip="settings.modules && !settings.modules.includes('terminal') ? 'title:'+$t('x.module.disabled.hint', {x: $t('terminal')}) : ''"
                                        :class="settings.modules && !settings.modules.includes('terminal') ? 'module-disabled-struct' : ''">
                                        <span
                                            :class="settings.modules && !settings.modules.includes('terminal') ? 'module-disabled' : ''">
                                            <LabelWithError
                                                :error="(selectedArticle && selectedArticle.isInArticleList) ? $t('article.in.article.list.terminal.error') : null"
                                                :label="$t('terminal.article')+ '*'">
                                                <Icon class="uk-margin-xsmall-left"
                                                      :uk-tooltip="'title:'+$t('terminal.article.info')"
                                                      icon="info"/>
                                            </LabelWithError>
                                            <SwitchButton
                                                v-model="createArticleTerminal"
                                                @input="isTerminalHandler"
                                                is-label-hidden
                                                with-cursor-not-allowed
                                                :is-disabled="(selectedArticle && selectedArticle.isInArticleList) || (settings.modules && !settings.modules.includes('terminal'))"
                                                label="new-terminal"/>
                                        </span>
                                    </div>
                                    <div class="uk-margin-bottom"/>


                                    <div v-if="createArticleType === 'orderControl'">
                                <span v-for="(inputField, key) in createArticleFieldsOcId" :key="inputField.name">
                                    <TextField v-model="inputField.value"
                                               :autocomplete="inputField.autocomplete"
                                               :autofocus="inputField.autofocus" :field-key="key"
                                               :hasPasswordMeter="inputField.hasPasswordMeter"
                                               :icon="inputField.icon"
                                               :is-optional="inputField.optional"
                                               :label="inputField.label"
                                               :name="inputField.name"
                                               :on-enter="createArticle"
                                               :options="inputField.options"
                                               :rules="inputField.rules"
                                               :steps="inputField.steps"
                                               :type="inputField.type"
                                               :validate="triggerCreateArticle"
                                               @setValid="setCreateArticleOcIdValid"
                                    />
                                    <div v-if="key < createArticleFieldsOcId.length-1" class="uk-margin-bottom"/>
                                </span>

                                        <div class="uk-margin-bottom"/>
                                    </div>

                                    <div v-if="createArticleType === 'noritsu'">
                                <span v-for="(inputField, key) in createArticleFieldsNoritsu" :key="inputField.name">
                                    <TextField v-model="inputField.value"
                                               :autocomplete="inputField.autocomplete"
                                               :autofocus="inputField.autofocus" :field-key="key"
                                               :hasPasswordMeter="inputField.hasPasswordMeter"
                                               :icon="inputField.icon"
                                               :is-optional="inputField.optional"
                                               :label="inputField.label"
                                               :name="inputField.name"
                                               :on-enter="createArticle"
                                               :options="inputField.options"
                                               :rules="inputField.rules"
                                               :steps="inputField.steps"
                                               :type="inputField.type"
                                               :validate="triggerCreateArticle"
                                               @setValid="setCreateArticleNoritsuIDValid"
                                    />
                                    <div v-if="key < createArticleFieldsNoritsu.length-1" class="uk-margin-bottom"/>
                                </span>

                                        <div class="uk-margin-bottom"/>
                                    </div>


                                    <div
                                        :uk-tooltip="settings.modules && settings.modules.includes('articleName') ? '' :'title:'+$t('x.module.disabled.hint', {x:$t('articleName')}) "
                                        :class="settings.modules && settings.modules.includes('articleName') ?'': 'module-disabled-struct'">
                                        <span
                                            :class="settings.modules && settings.modules.includes('articleName') ?'': 'module-disabled'">
                                <span v-for="(inputField, key) in createArticleFieldName" :key="inputField.name">
                                    <TextField v-model="inputField.value"
                                               :autocomplete="inputField.autocomplete"
                                               :autofocus="inputField.autofocus" :field-key="key"
                                               :hasPasswordMeter="inputField.hasPasswordMeter"
                                               :icon="inputField.icon"
                                               :is-optional="inputField.optional"
                                               :label="inputField.label"
                                               :name="inputField.name"
                                               :options="inputField.options"
                                               :rules="inputField.rules"
                                               :steps="inputField.steps"
                                               :type="inputField.type"
                                               :label-info="inputField.labelInfo"

                                               :validate="triggerCreateArticle"
                                               :on-enter="createArticle"

                                               @setValid="setCreateArticleNameValid"
                                    >
                                        <template v-slot:inLabel>
                                            <Icon class="uk-margin-xsmall-left"
                                                  :uk-tooltip="'title:'+$t('articleName.message')" icon="info"/>
                                        </template>
                                    </TextField>

                                    <div v-if="key < createArticleFieldName.length-1" class="uk-margin-bottom"/>
                                </span>

                                        <div class="uk-margin-bottom"/>
                                            </span>
                                    </div>


                                    <span v-for="(inputField, key) in createArticleFieldsDPI" :key="inputField.name">
                                    <TextField v-model="inputField.value"
                                               :autocomplete="inputField.autocomplete"
                                               :autofocus="inputField.autofocus" :field-key="key"
                                               :hasPasswordMeter="inputField.hasPasswordMeter"
                                               :icon="inputField.icon"
                                               :is-optional="inputField.optional"
                                               :label="inputField.label"
                                               :name="inputField.name"
                                               :options="inputField.options"
                                               :rules="inputField.rules"
                                               :steps="inputField.steps"
                                               :type="inputField.type"
                                               :label-info="inputField.labelInfo"

                                               :validate="triggerCreateArticle"
                                               :on-enter="createArticle"

                                               @setValid="setCreateArticleDPIValid"
                                    >
                                        <template v-slot:inLabel>
                                            <Icon class="uk-margin-xsmall-left" :uk-tooltip="'title:'+$t('dpi.message')"
                                                  icon="info"/>
                                        </template>
                                    </TextField>
                                    <div v-if="key < createArticleFieldsDPI.length-1" class="uk-margin-bottom"/>
                                </span>


                                    <div class="uk-margin-bottom"/>
                                    <LabelWithError :label="$t('favorite')+ '*'">
                                        <Icon class="uk-margin-xsmall-left" :uk-tooltip="'title:'+$t('favorite.info')"
                                              icon="info"/>
                                    </LabelWithError>
                                    <SwitchButton
                                        v-model="createArticleFavorite"
                                        is-label-hidden
                                        label="new-favorite"/>


                                    <div class="uk-margin-bottom"/>
                                    <LabelWithError
                                        :error="createArticleActivateError"
                                        :label="$t('active')+ '*'"/>
                                    <SwitchButton
                                        v-model="createArticleActive"
                                        :is-disabled="createArticleActivateDisabled"
                                        is-label-hidden
                                        label="new-active"/>

                                </div>
                                <div>

                            <span v-for="(inputField, key) in createArticleFields" :key="inputField.name">

                                        <TextField v-model="inputField.value"
                                                   :autocomplete="inputField.autocomplete"
                                                   :autofocus="inputField.autofocus" :field-key="key"
                                                   :hasPasswordMeter="inputField.hasPasswordMeter"
                                                   :icon="inputField.icon"
                                                   :is-optional="inputField.optional"
                                                   :label="inputField.label"
                                                   :name="inputField.name"
                                                   :on-enter="createArticle"
                                                   :options="inputField.options"
                                                   :rules="inputField.rules"
                                                   :steps="inputField.steps"
                                                   :type="inputField.type"
                                                   :validate="triggerCreateArticle"
                                                   @setValid="setCreateArticleValid"
                                                   :disabled="inputField.disabled"
                                                   :label-info="inputField.labelInfo"
                                                   :overlay="inputField.overlay"
                                        >
                                    <template v-if="key===1" v-slot:besidesLabel>
                                        <SwitchButton
                                            is-small
                                            @input="variableLengthHandler"
                                            :uk-tooltip="'title:'+(selectedArticle && selectedArticle.isInArticleList) ? $t('article.in.article.list.variable.error')+'.' : ''"
                                            :is-disabled="(selectedArticle && selectedArticle.isInArticleList)"
                                            v-model="createArticleVariableLength"
                                            :label="$t('variable.length')"/>
                                    </template>
                                </TextField>

                                <span v-if="key === 0">
                                    <div class="uk-margin-small-bottom"/>
                                    <span class="paper-info">{{
                                            $t('x.decimal.view.hint', {x: roundWidth(inputField.value)})
                                        }}</span>
                                </span>
                                <span v-if="key === 1 && !createArticleVariableLength">
                                    <div class="uk-margin-small-bottom"/>
                                    <span class="paper-info">{{
                                            $t('x.decimal.view.hint', {x: roundHeight(inputField.value)})
                                        }}</span>
                                </span>

                                <span v-if="key === 0">
                                    <div class="uk-margin-small-bottom"/>
                                    <span class="paper-info">{{ $t('suggested.widths') }}</span>
                                    <div class="uk-margin-xsmall-bottom"/>

                                    <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                        <div v-for="suggestedWidth in settings.suggestedWidths"
                                             :key="suggestedWidth">
                                            <div
                                                v-on:click="createArticleFields[0].value= suggestedWidth; createArticleFields[0].valid= true">
                                                <LabelSelect>
                                                    {{ getNumberFormatted(suggestedWidth) }}
                                                </LabelSelect>
                                            </div>
                                        </div>
                                    </div>
                                </span>

                                <div v-if="key < createArticleFields.length-1" class="uk-margin-bottom"/>
                            </span>


                                    <span v-if="createArticleVariableLength">
                                <div class="uk-margin-small-bottom"/>
                                <SwitchButton v-model="priceWithMaxLength"
                                              :label="$t('with.x', {x: $t('maximum.length')})"/>

                                <div v-if="priceWithMaxLength">
                                    <div class="uk-margin-bottom"/>

                                     <span v-for="(inputField, key) in maxLengthCreateFields" :key="inputField.name">
                                         <TextField v-model="inputField.value"
                                                    :autocomplete="inputField.autocomplete"
                                                    :autofocus="inputField.autofocus" :field-key="key"
                                                    :hasPasswordMeter="inputField.hasPasswordMeter"
                                                    :icon="inputField.icon"
                                                    :is-optional="inputField.optional"
                                                    :label="inputField.label"
                                                    :name="inputField.name"
                                                    :options="inputField.options"
                                                    :rules="inputField.rules"
                                                    :steps="inputField.steps"
                                                    :type="inputField.type"
                                                    :validate="triggerCreateArticle"
                                                    :label-info="inputField.labelInfo"

                                                    :on-enter="createArticle"
                                                    @setValid="setCreateArticleMaxLengthValid"
                                         />
                                         <div v-if="key < maxLengthCreateFields.length-1" class="uk-margin-bottom"/>
                                     </span>

                                </div>

                            </span>


                                    <div class="uk-margin-bottom"/>
                                    <TextField v-model="createArticlePaperFields[0].value"
                                               :autocomplete="createArticlePaperFields[0].autocomplete"
                                               :field-key="0"
                                               :icon="createArticlePaperFields[0].icon"
                                               :label="createArticlePaperFields[0].label"
                                               :name="createArticlePaperFields[0].name"
                                               :on-enter="createArticle"
                                               :rules="createArticlePaperFields[0].rules"
                                               :validate="triggerCreateArticle"
                                               @input="selectedSuggestedPaper= null"
                                               @setValid="setCreateArticlePaperValid"
                                    />

                                    <div class="uk-margin-small-bottom"/>
                                    <span class="paper-info">{{ $t('suggested.papers') }}</span>
                                    <div class="uk-margin-xsmall-bottom"/>

                                    <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                        <div v-for="paper in settings.suggestedPapers" :key="paper">
                                            <div
                                                v-on:click="createArticlePaperFields[0].value= $t(paper); selectedSuggestedPaper= paper; createArticlePaperFields[0].valid= true">
                                                <LabelSelect>
                                                    {{ $t(paper) }}
                                                </LabelSelect>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div>


                                    <CurrencyInput
                                        v-model="createPriceFields[0].value"
                                        :icon="createPriceFields[0].icon"
                                        :label="createPriceFields[0].label"
                                        :name="createPriceFields[0].name"
                                        :options="createArticleVariableLength ? getComputedCurrencyOptionsGraduated : getComputedCurrencyOptions"
                                        :rules="createPriceFields[0].rules"
                                        :validate="triggerCreateArticle"
                                        :label-info="createPriceFields[0].labelInfo"
                                    />

                                    <span v-if="createArticleVariableLength">
                                <div class="uk-margin-small-bottom"/>
                                <SwitchButton v-model="priceWithMinPrice"
                                              :label="$t('with.x', {x: $t('minimum.price')})"/>

                                <div v-if="priceWithMinPrice">
                                    <div class="uk-margin-bottom"/>
                                    <CurrencyInput
                                        v-model="createMinPriceFields[0].value"
                                        :icon="createMinPriceFields[0].icon"
                                        :label="createMinPriceFields[0].label"
                                        :name="createMinPriceFields[0].name"
                                        :options="getComputedCurrencyOptions"
                                        :rules="createMinPriceFields[0].rules"
                                        :validate="triggerCreateArticle"
                                    />
                                </div>

                            </span>


                                    <div class="uk-margin-bottom"/>
                                    <LabelWithError :label="$t('graduated.prices')+ ' ('+$t('optional')+')'"/>

                                    <span v-on:click="addGraduatedPrice()">
                                        <Button mode="primary">
                                            {{ $t('x.add', {x: $t('graduated.price')}) }}
                                        </Button>
                                    </span>
                                    <div class="uk-margin-bottom"/>

                                    <div
                                        v-if="!createArticleGraduatedPrices || createArticleGraduatedPrices.length === 0">
                                        {{ $t('none.x', {x: $t('graduated.prices')}) }}
                                    </div>
                                    <div v-else>

                                        <div class="">
                                            <div v-for="(graduatedPrice, key) in createArticleGraduatedPrices"
                                                 :key="'graduated.prices'+key"
                                                 class="uk-grid-small uk-grid-match"
                                                 uk-grid>
                                                <div class="uk-width-expand">
                                                    <TextField v-model="graduatedPrice[0].value"
                                                               :autocomplete="graduatedPrice[0].autocomplete"
                                                               :autofocus="graduatedPrice[0].autofocus" :field-key="0"
                                                               :hasPasswordMeter="graduatedPrice[0].hasPasswordMeter"
                                                               :icon="graduatedPrice[0].icon"
                                                               :identifier="key"
                                                               :is-optional="graduatedPrice[0].optional"
                                                               :label="graduatedPrice[0].label"
                                                               :name="graduatedPrice[0].name"
                                                               :on-enter="createArticle"
                                                               :options="graduatedPrice[0].options"
                                                               :rules="graduatedPrice[0].rules"
                                                               :steps="graduatedPrice[0].steps"
                                                               :type="graduatedPrice[0].type"
                                                               :validate="triggerCreateArticle"
                                                               :label-info="graduatedPrice[0].labelInfo"
                                                               @setValid="setCreateArticleGraduatesPricesValid"
                                                    />
                                                </div>
                                                <div class="uk-width-expand">
                                                    <CurrencyInput
                                                        v-model="graduatedPrice[1].value"
                                                        :icon="graduatedPrice[1].icon"
                                                        :is-optional="graduatedPrice[1].optional"
                                                        :label="graduatedPrice[1].label"
                                                        :name="graduatedPrice[1].name"
                                                        :on-enter="createArticle"
                                                        :options="createArticleVariableLength ? getComputedCurrencyOptionsGraduated : getComputedCurrencyOptions"
                                                        :rules="graduatedPrice[1].rules"
                                                        :steps="graduatedPrice[1].steps"
                                                        :type="graduatedPrice[1].type"
                                                        :label-info="graduatedPrice[1].labelInfo"
                                                    />

                                                </div>
                                                <div class="uk-width-auto">
                                                    <LabelWithError class="uk-invisible" label="p"/>
                                                    <span
                                                        :uk-tooltip="'title:'+$t('x.delete', {x: $t('graduated.price')})"
                                                        class="cursor-pointer"
                                                        v-on:click="removeGraduatedPrice(key)">
                                            <Icon icon="trash"/>
                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            <div class="uk-margin-medium-bottom"/>
                            <Button :action="createArticle"
                                    :is-loading="loadingCreateArticle"
                                    :text="$t(selectedArticle ? (selectedArticleClone ? 'x.duplicate' : 'x.save') : 'x.add', {x: $t('article')})"
                                    has-action is-large
                                    is-max
                                    mode="primary"/>

                            <div v-if="errorCreateArticle" class="uk-margin-medium-bottom"/>
                            <ErrorMessage :error="errorCreateArticle"/>
                        </Modal>

                        <span v-if="settings.modules && settings.modules.length >0">
                            <div class="uk-margin-medium-top"/>
                            <Button :action="newArticle" has-action mode="primary">
                                {{ $t('x.add', {x: $t('article')}) }}
                            </Button>

                            <div class="uk-margin-top"/>
                            <div v-if="!computedArticles || computedArticles.length === 0">
                                {{ $t('none.x', {x: $t('articles')}) }}
                            </div>
                            <div v-else class="uk-overflow-auto">


                                <table class="uk-table uk-table-middle uk-table-divider uk-table-hover">
                                    <thead>
                                    <tr>
                                        <th>
                                            {{ $t('type') }}
                                        </th>

                                        <th class="width-action"/>

                                         <th class="width-action"
                                             v-if="settings.modules && settings.modules.includes('articleName')"/>

                                        <th v-for="column in articleColumns"
                                            :key="column"
                                            :class="column === 'x' ? 'uk-table-shrink' : (['width', 'height'].includes(column) ? 'format-inputs' : 'width-input')">
                                            <span v-if="column !== 'x'">
                                                {{ $t(column) }}
                                            </span>
                                        </th>

                                        <th class="width-action"/>
                                        <th class="width-action"/>
                                        <th class="width-action"/>

                                        <th>
                                            {{ $t('active') }}
                                        </th>
                                        <th class="width-action"/>
                                        <th class="width-action"/>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(article, key) in computedArticles" :key="'article-'+key"
                                        :class="'cursor-pointer uk-text-nowrap' + (article.id === hoveredArticleId ? ' uk-table-hovered' : '')"

                                    >
                                        <td v-on:click="selectArticle(article)">
                                            <span v-if="article['type'] === 'mirage'">
                                                 Mirage-Datei:
                                            </span>
                                            <span v-else-if="article['type'] === 'noritsu'">
                                                 Noritsu-ID:
                                            </span>
                                            <span v-else-if="article['type'] === 'openPrint'">
                                                 {{ $t('openPrint') }}
                                             </span>
                                            <span v-else>
                                                OC-ID:
                                            </span>
                                        </td>

                                        <td v-on:click="selectArticle(article)">
                                            <span v-if="article['type'] === 'mirage'">
                                                <img v-if="article['mirageFile']"
                                                     :uk-tooltip="'title:'+$t('with.x', {x: $t('mirage.file')})"
                                                     class="mirage" src="../assets/mirage_active.png"/>
                                                <img v-else
                                                     :uk-tooltip="'title:'+$t('without.x', {x: $t('mirage.file')})"
                                                     class="mirage" src="../assets/mirage_deactive.png"/>
                                            </span>
                                            <span v-else-if="article['type'] === 'noritsu'">
                                                   {{ article['noritsuID'] }}
                                             </span>
                                            <span v-else-if="article['type'] === 'openPrint'">

                                             </span>
                                            <span v-else>
                                                {{ article['ocId'] }}
                                            </span>
                                        </td>

                                        <td v-if="settings.modules && settings.modules.includes('articleName')"
                                            v-on:click="selectArticle(article)">
                                           <Icon v-if="article['name']"
                                                 :uk-tooltip="'title:'+$t('with.x', {x: $t('name')})+': '+article['name']"
                                                 icon="tag"/>
                                        </td>

                                        <td v-for="column in articleColumns"
                                            :key="column" v-on:click="selectArticle(article)">
                                            <span v-if="column === 'graduated.prices'">
                                                <span v-if="settings.articles[key].graduatedPrices">
                                                    {{ settings.articles[key].graduatedPrices.length }}
                                                    <span v-if="settings.articles[key].graduatedPrices.length === 1">
                                                        {{ $t('graduated.price') }}
                                                    </span>
                                                    <span v-else>
                                                        {{ $t('graduated.prices') }}
                                                    </span>
                                                </span>
                                                <span v-else>
                                                    0 {{ $t('graduated.prices') }}
                                                </span>
                                            </span>

                                            <div v-if="column === 'paper'" class="paper-name">
                                                {{ getPaperName(article[column]) }}
                                            </div>

                                            <span v-else-if="column === 'width.x.height'">
                                                <span v-if="article['isHeightVariable']">
                                                     {{
                                                        getNumberFormatted(article.width)
                                                    }} {{ $t(settings.measurementUnit) }} x {{
                                                        $t('pictureLength')
                                                    }}  <span
                                                    v-if="article['maxLength']">({{
                                                        $t('max')
                                                    }} {{ article['maxLength'] }} {{
                                                        $t(settings.measurementUnit)
                                                    }})</span>
                                                </span>
                                                <span v-else>
                                                     {{
                                                        getNumberFormatted(article['width'])
                                                    }} x {{
                                                        getNumberFormatted(article['height'])
                                                    }} {{ $t(settings.measurementUnit) }}
                                                </span>
                                            </span>

                                            <span v-else-if="column === 'price'">
                                                {{
                                                    getNumberWithCurrency(article[column])
                                                }}{{
                                                    article.isHeightVariable ? ' ' + $t('per.x', {x: $t(settings.measurementUnit)}) : ''
                                                }}{{
                                                    article.isHeightVariable && article.minPrice ? ', ' + $t('min') + ' ' + getNumberWithCurrency(article.minPrice) : ''
                                                }}
                                            </span>

                                            <span v-else>
                                                {{ article[column] }}
                                            </span>

                                        </td>

                                        <td>
                                            <Icon v-if="article['isTerminal']"
                                                  :uk-tooltip="'title:'+$t('terminal.article')"
                                                  icon="desktop"/>
                                        </td>

                                        <td>
                                            <Icon v-if="article['isFavorite']" :uk-tooltip="'title:'+$t('favorite')"
                                                  icon="star"/>
                                        </td>


                                        <td>
                                            <Icon v-if="article['isInArticleList']"
                                                  :uk-tooltip="'title:'+$t('in.article.list')"
                                                  icon="link"/>
                                        </td>

                                        <td>
                                            <SwitchButton v-model="article['active']"
                                                          :is-disabled="articleSwitchButtonDisabled(article)"
                                                          :label="'switch'+key"
                                                          :tooltip="articleSwitchButtonTooltip(article)"
                                                          :value="article['active']"
                                                          is-label-hidden
                                                          @input="updateArticle(article, true)"/>

                                        </td>


                                        <td>
                                            <span :uk-tooltip="'title:'+$t('x.duplicate', {x: $t('article')})"
                                                  class="cursor-pointer"
                                                  v-on:click="cloneArticle(article)">
                                                <Icon icon="copy"/>
                                            </span>
                                        </td>


                                        <td>
                                            <span :uk-tooltip="'title:'+$t('x.delete', {x: $t('article')})"
                                                  class="cursor-pointer"
                                                  v-on:click="removeArticle(article)">
                                                <Icon icon="trash"/>
                                            </span>
                                        </td>


                                    </tr>

                                    </tbody>
                                </table>


                            </div>
                        </span>
                        <span v-else>
                            {{ $t('modules.none.active') }}
                        </span>

                        <div v-if="computedArticles && computedArticles.length > 0" class="uk-margin-top"/>
                        <Button v-if="computedArticles && computedArticles.length > 0" :action="newArticle"
                                has-action mode="primary">
                            {{ $t('x.add', {x: $t('article')}) }}
                        </Button>
                    </li>

                    <li>
                        <License :license-link="settings.licenseLink"
                                 v-if="isDateStringPast(settings.galleryLicensedUntil)"
                                 :licensed-until="settings.galleryLicensedUntil"
                                 :description="$t('gallery')"
                                 class="uk-margin-medium-bottom"
                        />
                        <div v-else-if="!settings.galleryLicensedUntil"
                             class="uk-margin-medium-bottom">
                            <InfoBox mode="primary" icon="info">
                                {{ $t('x.module.disabled.message', {x: $t('gallery')}) }}
                            </InfoBox>
                        </div>

                        <ul uk-tab="animation: uk-animation-fade">
                            <li v-for="tab in ['galleries', 'article.lists', 'license']" :key="tab">
                                <a href="#">{{ $t(tab) }}</a>
                            </li>
                        </ul>
                        <ul class="uk-switcher uk-margin">
                            <li>
                                <GalleriesForm
                                    :settings="settings"
                                    :disabled="settings.modules && !settings.modules.includes('gallery')"
                                    @updated="receiveSettings"
                                    :article-lists="settings.articleLists"/>
                            </li>
                            <li>
                                <ArticleListForm
                                    :disabled="settings.modules && !settings.modules.includes('gallery')"
                                    @updated="receiveSettings"
                                    :settings="settings"/>
                            </li>

                            <li>
                                <div class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match" uk-grid>
                                    <div v-if="!settings.galleryLicensedUntil"
                                         class="uk-width-1-1">
                                        <InfoBox mode="primary" icon="info">
                                            {{ $t('x.module.disabled.message', {x: $t('gallery')}) }}
                                        </InfoBox>
                                    </div>
                                    <div v-else class="uk-width-1-1">
                                        <License :license-link="settings.licenseLink"
                                                 :licensed-until="settings.galleryLicensedUntil"
                                                 :description="$t('gallery')"
                                        />
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </li>

                    <li>
                        <div class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match" uk-grid>
                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError :label="$t('currency')+'*'" is-label-large/>
                                    <select v-model="currency" class="uk-select uk-input-styled"
                                            v-on:change="successCurrency= false; $forceUpdate()">
                                        <option v-for="option in settings.allowedCurrencies" :key="option"
                                                :value="option">
                                            {{ option.toUpperCase() + " (" + getSymbolByCurrency(option) + ")" }}
                                        </option>
                                    </select>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveCurrency" :is-loading="loadingCurrency"
                                            :text="$t('x.save', {x: $t('currency')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorCurrency" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorCurrency"/>

                                    <div v-if="successCurrency" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('currency')})"
                                                    :success="successCurrency"/>
                                </Card>
                            </div>


                            <div>
                                <Card is-dark mode="default">

                                    <LabelWithError :label="$t('vat')+ ' ('+$t('optional')+')'" is-label-large/>
                                    <money
                                        v-model="vatFields[0].value"
                                        class="uk-input uk-input-styled"
                                        v-bind="{
                                          decimal:  isSwitchDecimal() ? '.' :  ',',
                                          thousands: isSwitchDecimal() ? ',' :  '.',
                                          suffix: ' %',
                                        }"
                                    />

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveVat" :is-loading="loadingVat"
                                            :text="$t('x.save', {x: $t('vat')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorVat" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorVat"/>

                                    <div v-if="successVat" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('vat')})"
                                                    :success="successVat"/>
                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('service.fee')" is-dark mode="default">


                                    <div v-if="isGalleryEnabled || isTerminalEnabled" class="section-text">
                                        <Translate translation-key="photoUpload"/>
                                    </div>

                                    <CurrencyInput
                                        v-model="serviceFeeFields[0].value"
                                        :icon="serviceFeeFields[0].icon"
                                        :label="serviceFeeFields[0].label"
                                        :name="serviceFeeFields[0].name"
                                        :options="getComputedCurrencyOptions"
                                        :rules="serviceFeeFields[0].rules"
                                        :validate="triggerServiceFee"
                                        @input="successServiceFee= false"
                                    />

                                    <div class="uk-margin-medium-bottom"/>
                                    <SwitchButton v-model="serviceFeeWithDiscount" :label="$t('with.discount')"
                                                  id="upload-feediscount"
                                                  @input="successServiceFee= false"/>

                                    <div v-if="serviceFeeWithDiscount" class="uk-animation-fade">
                                        <div class="uk-margin-bottom"/>
                                        <CurrencyInput
                                            v-model="serviceFeeFields[1].value"
                                            :icon="serviceFeeFields[1].icon"
                                            :label="serviceFeeFields[1].label"
                                            :name="serviceFeeFields[1].name"
                                            :options="getComputedCurrencyOptions"
                                            :rules="serviceFeeFields[1].rules"
                                            :validate="triggerServiceFee"
                                            @input="successServiceFee= false"
                                        />
                                    </div>



                                    <hr class="uk-margin-medium-top uk-margin-bottom" v-if="isGalleryEnabled"/>
                                    <div class="uk-margin-medium-top" v-if="isGalleryEnabled">
                                        <div class="section-text">
                                            <Translate translation-key="gallery"/>
                                        </div>


                                        <CurrencyInput
                                            v-model="serviceFeeFieldsGallery[0].value"
                                            :icon="serviceFeeFieldsGallery[0].icon"
                                            :label="serviceFeeFieldsGallery[0].label"
                                            :name="serviceFeeFieldsGallery[0].name"
                                            :options="getComputedCurrencyOptions"
                                            :rules="serviceFeeFieldsGallery[0].rules"
                                            :validate="triggerServiceFee"
                                            @input="successServiceFee= false"
                                        />

                                        <div class="uk-margin-medium-bottom"/>
                                        <SwitchButton v-model="serviceFeeWithDiscountGallery" :label="$t('with.discount')"
                                                      id="gallery-feediscount"
                                                      @input="successServiceFee= false"/>

                                        <div v-if="serviceFeeWithDiscountGallery" class="uk-animation-fade">
                                            <div class="uk-margin-bottom"/>
                                            <CurrencyInput
                                                v-model="serviceFeeFieldsGallery[1].value"
                                                :icon="serviceFeeFieldsGallery[1].icon"
                                                :label="serviceFeeFieldsGallery[1].label"
                                                :name="serviceFeeFieldsGallery[1].name"
                                                :options="getComputedCurrencyOptions"
                                                :rules="serviceFeeFieldsGallery[1].rules"
                                                :validate="triggerServiceFee"
                                                @input="successServiceFee= false"
                                            />
                                        </div>
                                    </div>



                                    <hr class="uk-margin-medium-top uk-margin-bottom" v-if="isTerminalEnabled"/>
                                    <div class="uk-margin-medium-top" v-if="isTerminalEnabled">
                                        <div class="section-text">
                                            <Translate translation-key="terminal"/>
                                        </div>


                                        <CurrencyInput
                                            v-model="serviceFeeFieldsTerminal[0].value"
                                            :icon="serviceFeeFieldsTerminal[0].icon"
                                            :label="serviceFeeFieldsTerminal[0].label"
                                            :name="serviceFeeFieldsTerminal[0].name"
                                            :options="getComputedCurrencyOptions"
                                            :rules="serviceFeeFieldsTerminal[0].rules"
                                            :validate="triggerServiceFee"
                                            @input="successServiceFee= false"
                                        />

                                        <div class="uk-margin-medium-bottom"/>
                                        <SwitchButton v-model="serviceFeeWithDiscountTerminal" :label="$t('with.discount')"
                                                      id="terminal-feediscount"
                                                      @input="successServiceFee= false"/>

                                        <div v-if="serviceFeeWithDiscountTerminal" class="uk-animation-fade">
                                            <div class="uk-margin-bottom"/>
                                            <CurrencyInput
                                                v-model="serviceFeeFieldsTerminal[1].value"
                                                :icon="serviceFeeFieldsTerminal[1].icon"
                                                :label="serviceFeeFieldsTerminal[1].label"
                                                :name="serviceFeeFieldsTerminal[1].name"
                                                :options="getComputedCurrencyOptions"
                                                :rules="serviceFeeFieldsTerminal[1].rules"
                                                :validate="triggerServiceFee"
                                                @input="successServiceFee= false"
                                            />
                                        </div>
                                    </div>


                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveServiceFee" :is-loading="loadingServiceFee"
                                            :text="$t('x.save', {x: $t('service.fee')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorServiceFee" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorServiceFee"/>

                                    <div v-if="successServiceFee" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('service.fee')})"
                                                    :success="successServiceFee"/>
                                </Card>
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError :error="errorPaymentMethodsInput" :label="$t('payment.methods')+'*'"
                                                    is-dark is-label-large/>


                                    <div v-if="isGalleryEnabled || isTerminalEnabled" class="section-text">
                                        <Translate translation-key="photoUpload"/>
                                    </div>
                                    <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                                        <div v-for="method in allowedPaymentMethods" :key="method.name">
                                            <span v-on:click="checkPaymentInputValidationPrepare()">
                                                <SwitchButton :icon="getIconByName(method.name)"
                                                              :id="'upload'+method.name"
                                                              v-model="method.value" :label="$t(method.name)"/>
                                            </span>
                                        </div>
                                    </div>

                                    <hr class="uk-margin-medium-top uk-margin-bottom" v-if="isGalleryEnabled"/>
                                    <div class="uk-margin-medium-top" v-if="isGalleryEnabled">
                                        <div class="section-text">
                                            <Translate translation-key="gallery"/>
                                        </div>
                                        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                                            <div v-for="method in allowedPaymentMethodsGallery" :key="method.name">
                                            <span v-on:click="checkPaymentInputValidationPrepare()">
                                                <SwitchButton :icon="getIconByName(method.name)"
                                                              :id="'gallery'+method.name"
                                                              v-model="method.value" :label="$t(method.name)"/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>


                                    <hr class="uk-margin-medium-top uk-margin-bottom" v-if="isTerminalEnabled"/>
                                    <div class="uk-margin-medium-top" v-if="isTerminalEnabled">
                                        <div class="section-text">
                                            <Translate translation-key="terminal"/>
                                        </div>
                                        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                                            <div v-for="method in allowedPaymentMethodsTerminal" :key="method.name">
                                            <span v-on:click="checkPaymentInputValidationPrepare()">
                                                <SwitchButton :icon="getIconByName(method.name)"
                                                              :id="'terminal'+method.name"
                                                              v-model="method.value" :label="$t(method.name)"/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="savePaymentMethods" :is-loading="loadingPaymentMethods"
                                            :text="$t('x.save', {x: $t('payment.methods')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorPaymentMethods" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorPaymentMethods"/>

                                    <div v-if="successPaymentMethods" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('payment.methods')})"
                                                    :success="successPaymentMethods"/>
                                </Card>
                            </div>


                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError :label="$t('shipping.costs')+ ' ('+$t('optional')+')'" is-dark
                                                    is-label-large/>


                                    <span v-on:click="addGraduatedShipping()">
                                        <Button mode="primary">
                                            {{ $t('x.add', {x: $t('graduated.price')}) }}
                                        </Button>
                                    </span>
                                    <div class="uk-margin-bottom"/>

                                    <div
                                        v-if="!this.shippingGraduatedPrices || this.shippingGraduatedPrices.length === 0">
                                        {{ $t('none.x', {x: $t('graduated.prices')}) }}
                                    </div>
                                    <div v-else>

                                        <div v-for="(graduatedPrice, key) in shippingGraduatedPrices"
                                             :key="'graduated.prices'+key"
                                             class="uk-grid-small uk-grid-match"
                                             uk-grid>
                                            <div class="uk-width-expand">
                                                <TextField v-model="graduatedPrice[0].value"
                                                           :autocomplete="graduatedPrice[0].autocomplete"
                                                           :autofocus="graduatedPrice[0].autofocus" :field-key="0"
                                                           :hasPasswordMeter="graduatedPrice[0].hasPasswordMeter"
                                                           :icon="graduatedPrice[0].icon"
                                                           :identifier="key"
                                                           :is-optional="graduatedPrice[0].optional"
                                                           :label="graduatedPrice[0].label"
                                                           :name="graduatedPrice[0].name"
                                                           :on-enter="saveShippingGraduatedPrices"
                                                           :options="graduatedPrice[0].options"
                                                           :rules="graduatedPrice[0].rules"
                                                           :steps="graduatedPrice[0].steps"
                                                           :type="graduatedPrice[0].type"
                                                           :validate="triggerShippingGraduatedPrices"
                                                           @setValid="setShippingGraduatedPricesValid"
                                                />
                                            </div>
                                            <div class="uk-width-expand">
                                                <CurrencyInput
                                                    v-model="graduatedPrice[1].value"
                                                    :icon="graduatedPrice[1].icon"
                                                    :is-optional="graduatedPrice[1].optional"
                                                    :label="graduatedPrice[1].label"
                                                    :name="graduatedPrice[1].name"
                                                    :on-enter="saveShippingGraduatedPrices"
                                                    :options="createArticleVariableLength ? getComputedCurrencyOptionsGraduated : getComputedCurrencyOptions"
                                                    :rules="graduatedPrice[1].rules"
                                                    :steps="graduatedPrice[1].steps"
                                                    :type="graduatedPrice[1].type"
                                                />

                                            </div>
                                            <div class="uk-width-auto">
                                                <LabelWithError class="uk-invisible" label="p"/>
                                                <span :uk-tooltip="'title:'+$t('x.delete', {x: $t('graduated.price')})"
                                                      class="cursor-pointer"
                                                      v-on:click="removeShippingGraduatedPrice(key)">
                                            <Icon icon="trash"/>
                                        </span>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveShippingGraduatedPrices"
                                            :is-loading="loadingShippingGraduatedPrices"
                                            :text="$t('x.save', {x: $t('shipping.costs')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorShippingGraduatedPrices" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorShippingGraduatedPrices"/>

                                    <div v-if="successShippingGraduatedPrices" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('shipping.costs')})"
                                                    :success="successShippingGraduatedPrices"/>
                                </Card>
                            </div>


                        </div>
                    </li>

                    <li>
                        <div class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match" uk-grid>

                            <div>
                                <Card is-dark mode="default">
                                    <span v-for="(inputField, key) in nameFields" :key="inputField.name">
                                        <TextField v-model="inputField.value"
                                                   :autocomplete="inputField.autocomplete"
                                                   :autofocus="inputField.autofocus"
                                                   :field-key="key" :hasPasswordMeter="inputField.hasPasswordMeter"
                                                   :icon="inputField.icon"
                                                   :is-optional="inputField.optional"
                                                   :label="inputField.label"
                                                   :name="inputField.name"
                                                   :on-enter="saveName"
                                                   :options="inputField.options"
                                                   :rules="inputField.rules"
                                                   :steps="inputField.steps"
                                                   :type="inputField.type"
                                                   :validate="triggerName"
                                                   is-label-large
                                                   @setValid="setNameValid"
                                        />
                                        <div v-if="key < nameFields.length-1" class="uk-margin-bottom"/>
                                    </span>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveName" :is-loading="loadingName"
                                            :text="$t('x.save', {x: $t('name')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorName" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorName"/>

                                    <div v-if="successName" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('name')})"
                                                    :success="successName"/>
                                </Card>
                            </div>

                            <div>
                                <LogoForm :data="settings.logo"
                                          @updated="receiveInfos"
                                          :allowed-extensions="fileExtensionsBackground"
                                          :allowed-size="allowedFaviconSize"
                                          :allowed-logo-positions="settings.allowedLogoPositions"
                                          :position="settings.logoPosition"
                                />
                            </div>

                            <div>
                                <Card :title="$t('termsAndConditions')+ ' ('+$t('optional')+')'" is-dark mode="default">
                                    <vue-editor v-model="settings.termsAndConditions"
                                                v-on:input="successTermsAndConditions= false"/>

                                    <div class="uk-margin-medium-top"/>
                                    <Button :action="saveTermsAndConditions" :is-loading="loadingTermsAndConditions"
                                            :text="$t('x.save', {x: $t('termsAndConditions')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorTermsAndConditions" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorTermsAndConditions"/>

                                    <div v-if="successTermsAndConditions" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('termsAndConditions')})"
                                                    :success="successTermsAndConditions"/>
                                </Card>
                            </div>
                            <div>
                                <Card :title="$t('dataPrivacy') + ' ('+$t('optional')+')'" is-dark mode="default">
                                    <vue-editor v-model="settings.dataPrivacy"
                                                v-on:input="successDataPrivacy= false"/>

                                    <div class="uk-margin-medium-top"/>
                                    <Button :action="saveDataPrivacy" :is-loading="loadingDataPrivacy"
                                            :text="$t('x.save', {x: $t('dataPrivacy')})" has-action
                                            is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorDataPrivacy" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorDataPrivacy"/>

                                    <div v-if="successDataPrivacy" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('dataPrivacy')})"
                                                    :success="successDataPrivacy"/>
                                </Card>
                            </div>
                            <div>
                                <Card :title="$t('siteNotice')+ ' ('+$t('optional')+')'" is-dark mode="default">
                                    <vue-editor v-model="settings.siteNotice" v-on:input="successSiteNotice= false"/>

                                    <div class="uk-margin-medium-top"/>
                                    <Button :action="saveSiteNotice" :is-loading="loadingSiteNotice"
                                            :text="$t('x.save', {x: $t('siteNotice')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorSiteNotice" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorSiteNotice"/>

                                    <div v-if="successSiteNotice" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('siteNotice')})"
                                                    :success="successSiteNotice"/>
                                </Card>
                            </div>

                            <div>
                                <Card :title="$t('email.signature') + ' ('+$t('optional')+')'" is-dark mode="default">
                                    <vue-editor v-model="settings.emailSignature"
                                                v-on:input="successEmailSignature= false"/>

                                    <div class="uk-margin-medium-top"/>
                                    <Button :action="saveEmailSignature" :is-loading="loadingEmailSignature"
                                            :text="$t('x.save', {x: $t('email.signature')})" has-action
                                            is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorEmailSignature" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorEmailSignature"/>

                                    <div v-if="successEmailSignature" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('email.signature')})"
                                                    :success="successEmailSignature"/>
                                </Card>
                            </div>

                            <div class="uk-width-1-1">
                                <Card is-dark mode="default">
                                    <LabelWithError :error="picturesError" :label="$t('background.pictures')" is-dark
                                                    is-label-large is-optional/>

                                    <ul class="uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1 uk-grid-match"
                                        uk-grid uk-sortable="handle: .uk-sortable-handle; group: sortable-group">

                                        <li v-for="(picture, key) in settings.pictures" :key="key">

                                            <Modal :id="'picture-'+key">
                                                <img v-if="pictures[key]" :src="getLocalFileUrl(pictures[key])" uk-img/>
                                                <img v-else-if="picture" :src="apiUrl+'/pictures/'+picture"
                                                     uk-img/>
                                            </Modal>

                                            <div class="uk-card uk-card-default uk-card-hover">
                                                <div class="uk-inline uk-transition-toggle uk-width-1-1">

                                                    <div v-if="pictures[key]"
                                                         class="uk-height-medium uk-background-cover"
                                                         :data-src="getLocalFileUrl(pictures[key])" uk-img/>
                                                    <div v-else-if="picture"
                                                         class="uk-height-medium uk-background-cover"
                                                         :data-src="apiUrl+'/pictures/'+picture" uk-img/>
                                                    <div v-else class="uk-height-medium uk-background-cover"
                                                         :data-src="require('../assets/dummy.png')" uk-img/>

                                                    <input :id="'filePicture'+key"
                                                           :ref="'filePicture'+key"
                                                           :accept="'.'+fileExtensionsBackground.join(', .')"
                                                           type="file"
                                                           class="uk-hidden"
                                                           v-on:click="$refs['filePicture'+key].value=null"
                                                           v-on:change="filePicturesHandle(key)"
                                                    />

                                                    <div v-if="picture || pictures[key]"
                                                         class="uk-position-cover uk-overlay-primary uk-flex uk-flex-center uk-flex-middle uk-transition-fade">
                                                        <div class="uk-position-center uk-text-center">

                                                            <ToggleItem :toggle="'target: #picture-'+key">
                                                                <Icon :ratio="1.5"
                                                                      :uk-tooltip="'title:'+$t('show')"
                                                                      class="cursor-pointer"
                                                                      icon="search"/>
                                                            </ToggleItem>

                                                            <span class="uk-margin-small-right"/>
                                                            <span v-on:click="triggerInputDialogPicture(key)">
                                                                <Icon :ratio="1.5"
                                                                      :uk-tooltip="'title:'+$t('replace')"
                                                                      class="cursor-pointer"
                                                                      icon="cloud-upload"/>
                                                            </span>

                                                            <span class="uk-margin-small-right"/>
                                                            <span v-on:click="filePicturesDelete(key)">
                                                                    <Icon :ratio="1.5"
                                                                          :uk-tooltip="'title:'+$t('delete')"
                                                                          class="cursor-pointer" icon="trash"/>
                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="uk-position-center uk-text-center uk-overlay-hover">

                                                            <span v-on:click="triggerInputDialogPicture(key)">
                                                                <Icon :ratio="1.5"
                                                                      :uk-tooltip="'title:'+$t('add')"
                                                                      class="cursor-pointer dark"
                                                                      icon="cloud-upload"/>
                                                            </span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </li>
                                    </ul>

                                    <div class="uk-margin-medium-top"/>
                                    <Button :action="savePictures" :is-loading="loadingPictures"
                                            :text="$t('x.save', {x: $t('background.pictures')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorPictures" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorPictures"/>

                                    <div v-if="successPictures" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('background.pictures')})"
                                                    :success="successPictures"/>
                                </Card>
                            </div>

                        </div>
                    </li>


                    <li>
                        <div class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match" uk-grid>

                            <div v-if="settings.modules && !settings.modules.includes('whiteLabel')"
                                 class="uk-width-1-1">
                                <InfoBox mode="primary" icon="info">
                                    {{ $t('x.module.disabled.message', {x: $t('whiteLabel')}) }}
                                </InfoBox>
                            </div>

                            <div>
                                <SMTPConfigForm :smtp-config="settings.smtpConfig"
                                                v-if="settings.smtpConfig"
                                                @updated="receiveSettings"
                                                :disabled="settings.modules && !settings.modules.includes('whiteLabel')"/>
                            </div>

                            <div>
                                <FaviconForm :data="settings.favicon"
                                             :allowed-extensions="allowedFaviconExtensions"
                                             :allowed-size="allowedFaviconSize"
                                             @updated="receiveSettings"
                                             :disabled="settings.modules && !settings.modules.includes('whiteLabel')"
                                />
                            </div>

                            <div>
                                <SocialMediaImageForm :data="settings.socialMediaImage"
                                                      :allowed-extensions="fileExtensionsSocialMediaImage"
                                                      :allowed-size="allowedFaviconSize"
                                                      @updated="receiveSettings"
                                                      :disabled="settings.modules && !settings.modules.includes('whiteLabel')"
                                />
                            </div>

                            <div>
                                <FooterText :text="settings.footerText"
                                            @updated="receiveInfos"
                                            :disabled="settings.modules && !settings.modules.includes('whiteLabel')"/>
                            </div>

                            <div v-if="settings.modules && settings.modules.includes('terminal')">
                                <ScreensaversText :text="settings.screensaversText"
                                                  @updated="receiveInfos"
                                                  :disabled="settings.modules && !settings.modules.includes('whiteLabel')"/>
                            </div>

                        </div>
                    </li>


                    <li>
                        <div class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match" uk-grid>

                            <div v-if="!settings.terminalLicensedUntil"
                                 class="uk-width-1-1">
                                <InfoBox mode="primary" icon="info">
                                    {{ $t('x.module.disabled.message', {x: $t('terminal')}) }}
                                </InfoBox>
                            </div>
                            <div v-else class="uk-width-1-1">
                                <License :license-link="settings.licenseLink"
                                         :licensed-until="settings.terminalLicensedUntil"
                                         :description="$t('terminal')"
                                />
                            </div>

                            <div>
                                <TerminalConnectorForm
                                    @created="receiveSettings"
                                    :is-terminal-registered="settings.isTerminalRegistered"
                                    :connectors="settings.terminalConnectors"
                                    :disabled="settings.modules && !settings.modules.includes('terminal')"/>
                            </div>

                            <div>
                                <TerminalRegisterForm
                                    :disabled="settings.modules && !settings.modules.includes('terminal')"/>
                            </div>

                            <div>
                                <TerminalPinForm
                                    :disabled="settings.modules && !settings.modules.includes('terminal')"/>
                            </div>

                            <div>
                                <ScreensaversInterval
                                    :slider-interval="settings.terminalSliderIntervalInSeconds"
                                    :slider-video-adjusted="settings.terminalSliderIntervalVideoAdjusted"
                                    :disabled="settings.modules && !settings.modules.includes('terminal')"/>
                            </div>

                            <div class="uk-width-1-1">
                                <TerminalScreensaversForm
                                    :data="settings.screensavers"
                                    :allowed-extensions="fileExtensionsScreensavers"
                                    :allowed-size="allowedSizeScreensavers"
                                    :disabled="settings.modules && !settings.modules.includes('terminal')"/>
                            </div>

                        </div>
                    </li>

                    <li v-if="false">
                        <SmartSignageForm
                            :settings="settings"
                            :disabled="settings.modules && !settings.modules.includes('gallery')"
                            @updated="receiveSettings"/>
                    </li>


                    <li>
                        <Card is-dark mode="default">

                            <div uk-grid>
                                <div class="uk-width-1-1 uk-text-center cloudConnector-title">
                                    CloudConnector
                                </div>
                            </div>

                            <div class="uk-child-width-auto uk-grid-match uk-flex-center" uk-grid>

                                <div v-for="download in settings.downloaders" :key="download.name"
                                     class="uk-text-center">
                                    <a :href="download.link">
                                        <RoundButton icon="cloud-download"/>
                                    </a>
                                    <div class="uk-margin-small-top system-title uk-link-reset">
                                        <a :href="download.link">
                                            {{ download.name }}
                                        </a>
                                    </div>
                                </div>

                                <div class="uk-width-1-1">
                                    <InfoBox v-if="settings.isDownloaderRegistered"
                                             mode="success">
                                        {{ $t('cloudConnector.setup.success') }}
                                        <br/><br/>
                                        {{ $t('cloudConnector.setup.hint') }}
                                    </InfoBox>

                                    <InfoBox v-else>
                                        {{ $t('cloudConnector.setup.description') }}

                                        <div class="uk-margin-top"/>
                                        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                                            <div>
                                                <span
                                                    class="uk-margin-small-right">1.</span>{{
                                                    $t('cloudConnector.step1.message')
                                                }}
                                            </div>
                                            <div><span
                                                class="uk-margin-small-right">2.</span>{{
                                                    $t('cloudConnector.step2.message')
                                                }}
                                            </div>
                                            <div><span
                                                class="uk-margin-small-right">3.</span>{{
                                                    $t('cloudConnector.step3.message')
                                                }}
                                            </div>
                                        </div>
                                    </InfoBox>
                                </div>

                                <div class="uk-width-1-1">
                                    <span v-if="settings.isDownloaderRegistered || codeRegened">
                                        <ToggleItem :is-disabled="code !== null"
                                                    toggle="target: #regen-code">
                                            <Button :text="$t('regen.setup.code')"
                                                    :is-disabled="code !== null"
                                                    is-large is-max mode="danger"/>
                                        </ToggleItem>

                                        <Modal id="regen-code" :title="$t('regen.setup.code')">
                                            <div class="uk-margin-medium-bottom uk-text-center"
                                                 v-html="$t('regen.setup.code.warning')"/>

                                            <div v-if="!successReset" class="uk-flex uk-flex-center" uk-grid>
                                                <div>
                                                    <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                                            is-max is-min-width
                                                            mode="default"/>
                                                </div>
                                                <div>
                                                    <Button :action="regenCode" :is-loading="loadingCode"
                                                            :text="$t('regen')"
                                                            has-action
                                                            is-large is-max
                                                            is-min-width mode="danger"/>
                                                </div>
                                            </div>
                                        </Modal>
                                    </span>

                                    <Button
                                        v-else
                                        :text="$t('gen.setup.code')"
                                        is-large
                                        :action="genCode" has-action
                                        :is-loading="loadingCode"
                                        is-max is-min-width
                                        :is-disabled="code !== null"
                                        mode="success"/>

                                    <div v-if="errorCode" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorCode"/>


                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <div v-if="successCode" class="uk-animation-fade" id="code">
                                        <TextField icon="code"
                                                   classes="cursor-pointer"
                                                   name="setup-code"
                                                   label="setup.code"
                                                   :tooltip="$t('copy.to.clipboard')"
                                                   v-model="codeValue"
                                                   :on-click="codeToClipboard"
                                                   :on-change="resetCode"
                                                   :action-function="codeToClipboard"
                                                   action-icon="copy-clipboard"
                                                   :action-tooltip="$t('copy.to.clipboard')"/>
                                    </div>

                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage
                                        :message="$t('gen.setup.code.success')"
                                        :success="successCode"/>

                                </div>
                            </div>

                        </Card>
                    </li>


                    <li>
                        <div class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match" uk-grid>

                            <div v-if="settings.licensedUntil" class="uk-width-1-1">
                                <License :license-link="settings.licenseLink"
                                         :licensed-until="settings.licensedUntil"
                                         :description="$t('photoUpload')"
                                />
                            </div>

                            <div>
                                <Card is-dark mode="default">
                                    <span v-for="(inputField, key) in emailFields" :key="inputField.name">
                                        <TextField v-model="inputField.value"
                                                   :autocomplete="inputField.autocomplete"
                                                   :autofocus="inputField.autofocus"
                                                   :field-key="key"
                                                   :hasPasswordMeter="inputField.hasPasswordMeter"
                                                   :icon="inputField.icon"
                                                   :is-optional="inputField.optional"
                                                   :label="inputField.label"
                                                   :name="inputField.name"
                                                   :on-enter="saveEmail"
                                                   :options="inputField.options"
                                                   :rules="inputField.rules"
                                                   :steps="inputField.steps"
                                                   :type="inputField.type"
                                                   :validate="triggerEmail"
                                                   is-label-large
                                                   @setValid="setEmailValid"
                                        />
                                        <div v-if="key < emailFields.length-1" class="uk-margin-bottom"/>
                                    </span>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveEmail" :is-loading="loadingEmail"
                                            :text="$t('x.save', {x: $t('emailAddress')})"
                                            has-action is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorEmail" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorEmail"/>

                                    <div v-if="successEmail" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('emailAddress')})"
                                                    :success="successEmail"/>
                                </Card>
                            </div>
                            <div>
                                <Card :title="$t('password')" is-dark mode="default">
                                    <span v-for="(inputField, key) in passwordFields" :key="inputField.name">
                                        <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                                                   :autofocus="inputField.autofocus" :field-key="key"
                                                   :hasPasswordMeter="inputField.hasPasswordMeter"
                                                   :icon="inputField.icon"
                                                   :is-optional="inputField.optional"
                                                   :label="inputField.label"
                                                   :name="inputField.name"
                                                   :on-enter="savePassword"
                                                   :rules="inputField.rules"
                                                   :type="inputField.type"
                                                   :validate="triggerPassword"
                                                   @setValid="setPasswordValid"
                                        />
                                        <div v-if="key < passwordFields.length-1" class="uk-margin-bottom"/>
                                    </span>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="savePassword" :is-loading="loadingPassword"
                                            :text="$t('x.save', {x: $t('password')})" has-action
                                            is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorPassword" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :custom-errors="[{code: 403, message: $t('password.invalid')}]"
                                                  :error="errorPassword"/>

                                    <div v-if="successPassword" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('password')})"
                                                    :success="successPassword"/>
                                </Card>
                            </div>

                            <div v-if="settings.documents && settings.documents.length > 0">
                                <Card :title="$t('documents')" is-dark mode="default">
                                    <div class="uk-margin-medium-bottom"/>

                                    <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                        <div v-for="document in settings.documents" :key="document"
                                             class="uk-text-center">
                                            <span v-on:click="downloadDocument(document)">
                                                <RoundButton :is-loading="documentMeta[document]"
                                                             icon="cloud-download"/>
                                            </span>
                                            <div class="document-name">{{ document }}</div>
                                        </div>
                                    </div>

                                </Card>
                            </div>


                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError :label="$t('mandatory.fields')" is-label-large/>

                                    <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                                        <div v-for="method in allowedConfigurableMandatoryFields" :key="method.name">
                                            <span v-on:click="successMandatoryFields= false">
                                                <SwitchButton :icon="getIconByName(method.name)"
                                                              v-model="method.value" :label="$t(method.name)"/>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveMandatoryFields" :is-loading="loadingMandatoryFields"
                                            :text="$t('x.save', {x: $t('mandatory.fields')})" has-action
                                            is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorMandatoryFields" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorMandatoryFields"/>

                                    <div v-if="successMandatoryFields" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('mandatory.fields')})"
                                                    :success="successMandatoryFields"/>
                                </Card>
                            </div>

                            <div>
                                <ShippingCountriesForm
                                    @updated="receiveSettings"
                                    :shipping-countries="settings.shippingCountries"
                                    :allowed-shipping-countries="settings.allowedShippingCountries"/>
                            </div>


                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError :label="$t('absence')" is-label-large/>

                                    <SwitchButton v-model="absenceActive" :label="$t('active')"
                                                  @input="successAbsence= false"/>

                                    <div v-if="absenceActive" class="uk-animation-fade">
                                        <div class="uk-margin-bottom"/>

                                        <v-date-picker
                                            v-model="range"
                                            is-range
                                            is-expanded
                                            mode="date"
                                            is24hr
                                            :locale="settings.language"
                                        />
                                    </div>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveAbsence" :is-loading="loadingAbsence"
                                            :text="$t('x.save', {x: $t('absence')})" has-action
                                            is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorAbsence" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorAbsence"/>

                                    <div v-if="successAbsence" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage :message="$t('x.changes.saved', {x: $t('absence')})"
                                                    :success="successAbsence"/>
                                </Card>
                            </div>


                            <div>
                                <Card is-dark mode="default">
                                    <LabelWithError :label="$t('system.language')+ ' '+$t('and') +' '+$t('country')+'*'"
                                                    is-label-large/>
                                    <select v-model="settings.language" class="uk-select uk-input-styled"
                                            v-on:change="successLanguage= false; $forceUpdate()">
                                        <option v-for="option in languages" :key="option"
                                                :value="option">
                                            {{ option.toUpperCase() }} - {{ $t(getCountryByLang(option)) }}
                                        </option>
                                    </select>

                                    <div class="uk-margin-medium-bottom"/>
                                    <Button :action="saveLanguage" :is-loading="loadingLanguage"
                                            :text="$t('x.save', {x: $t('system.language')+ ' '+$t('and') +' '+$t('country')})"
                                            has-action
                                            is-large
                                            is-max
                                            mode="primary"/>

                                    <div v-if="errorLanguage" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorLanguage"/>

                                    <div v-if="successLanguage" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage
                                        :message="$t('x.changes.saved', {x: $t('system.language')+ ' '+$t('and') +' '+$t('country')})"
                                        :success="successLanguage"/>
                                </Card>
                            </div>

                            <div>
                                <MeasurementUnitForm
                                    @updated="receiveSettings"
                                    :measurement-unit="settings.measurementUnit"
                                    :measurement-units="settings.allowedMeasurementUnits"/>
                            </div>

                            <div>
                                <Card :title="$t('modules')" is-dark mode="default">
                                    <Description :text="$t('active.modules.message')"/>

                                    <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                        <div v-for="module in settings.modules" :key="module">
                                            <Label type="info">
                                                {{ capitalize($t(module)) }}
                                            </Label>
                                        </div>

                                        <div v-if="!settings.modules || settings.modules.length === 0">
                                            {{ $t('no.modules') }}
                                        </div>
                                    </div>

                                    <span v-if="settings.inactiveModules">
                                        <hr class="uk-margin-medium-top uk-margin-medium-bottom"/>

                                        <Description :text="$t('inactive.modules.message')"/>
                                        <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                            <div v-for="module in settings.inactiveModules" :key="module">
                                                <Label type="default">
                                                    {{ capitalize($t(module)) }}
                                                </Label>
                                            </div>
                                        </div>
                                    </span>


                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('limits')" is-dark mode="default">
                                    <Description :text="$t('limits.message')"/>

                                    <div class="uk-grid-small uk-child-width-1-1" uk-grid>

                                        <div uk-grid>
                                            <div class="uk-width-expand">
                                                <Translate translation-key="limit.pictures"/>
                                            </div>
                                            <div class="uk-width-auto">
                                                <Label type="info">{{ getInfos.maxPicturesPerOrder }}</Label>
                                            </div>
                                        </div>

                                        <div uk-grid>
                                            <div class="uk-width-expand">
                                                <Translate translation-key="limit.picture.size"/>
                                            </div>
                                            <div class="uk-width-auto">
                                                <Label type="info">{{ getInfos.maxFileSizeInMB }} MB</Label>
                                            </div>
                                        </div>

                                    </div>

                                    <span v-if="settings.inactiveUploadModules">
                                        <hr class="uk-margin-medium-top uk-margin-medium-bottom"/>
                                        <Description
                                            :text="$t(settings.inactiveUploadModules.length===1 ? 'limit.module.message':'limit.modules.message')"/>
                                        <div class="uk-grid-small uk-child-width-auto" uk-grid>
                                            <div v-for="module in settings.inactiveUploadModules" :key="module">
                                                <Label type="default">
                                                    {{ capitalize($t(module)) }}
                                                </Label>
                                            </div>
                                        </div>
                                    </span>


                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('version')" is-dark mode="default">
                                    <Description :text="$t('version.message')"/>
                                    <Label type="info">{{ settings.version }}</Label>
                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('logout')" is-dark mode="default">
                                    <Description :text="$t('logout.message')"/>

                                    <Button :action="logout" :text="$t('logout')" has-action is-large is-max
                                            mode="default"/>
                                </Card>
                            </div>


                            <div>
                                <Card :title="$t('reset')" is-dark mode="default">
                                    <Description :text="$t('system.reset.message')"/>
                                    <ToggleItem toggle="target: #reset">
                                        <Button :text="$t('system.reset')" is-large is-max mode="danger"/>
                                    </ToggleItem>

                                    <Modal id="reset" :title="$t('system.reset')">
                                        <div v-if="!successReset" class="uk-margin-medium-bottom uk-text-center"
                                             v-html="$t('system.reset.confirm')"/>

                                        <div v-if="!successReset" class="uk-flex uk-flex-center" uk-grid>
                                            <div>
                                                <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                                        is-max is-min-width
                                                        mode="default"/>
                                            </div>
                                            <div>
                                                <Button :action="reset" :is-loading="loadingReset"
                                                        :text="$t('system.reset')"
                                                        has-action
                                                        is-large is-max
                                                        is-min-width mode="danger"/>
                                            </div>
                                        </div>

                                        <div v-if="successReset" class="uk-margin-medium-bottom"/>
                                        <SuccessMessage :message="$t('reset.success')"
                                                        :success="successReset"/>

                                        <div v-if="successReset" class="uk-margin-bottom"/>
                                        <LinkObject v-if="successReset" to="setup">
                                            <Button :text="$t('setup')" class="uk-modal-close" is-large is-max
                                                    mode="primary"/>
                                        </LinkObject>
                                    </Modal>

                                    <div v-if="errorReset" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorReset"/>
                                </Card>
                            </div>

                        </div>
                    </li>


                </ul>
            </Card>

        </LoadingStruct>

    </div>
</template>

<script>

import LoadingStruct from "@/views/LoadingStruct";
import Card from "@/components/generic/Card";
import SwitchButton from "@/components/generic/SwitchButton";
import Icon from "@/components/generic/Icon";
import Button from "@/components/generic/Button";
import UIkit from "uikit";
import {VueEditor} from "vue2-editor";
import TextField from "@/components/generic/TextField";
import Description from "@/components/Description";
import Modal from "@/components/generic/Modal";
import ToggleItem from "@/components/generic/ToggleItem";
import Vue from 'vue';
import axios from "axios";
import ErrorMessage from "@/components/generic/ErrorMessage";
import SuccessMessage from "@/components/generic/SuccessMessage";
import LinkObject from "@/components/LinkObject";
import LabelWithError from "@/components/generic/LabelWithError";
import Label from "@/components/Label";
import CurrencyInput from "@/components/generic/CurrencyInput";
import LabelSelect from "@/components/LabelSelect";
import RoundButton from "@/components/RoundButton";
import VueCookie from "vue-cookie";
import dayjs from "dayjs";
import InfoBox from "@/components/generic/InfoBox.vue";
import SMTPConfigForm from "@/components/forms/SMTPConfigForm.vue";
import FaviconForm from "@/components/forms/FaviconForm.vue";
import LogoForm from "@/components/forms/LogoForm.vue";
import SocialMediaImageForm from "@/components/forms/SocialMediaImageForm.vue";
import MeasurementUnitForm from "@/components/forms/MeasurementUnitForm.vue";
import TerminalPinForm from "@/components/forms/TerminalPinForm.vue";
import TerminalConnectorForm from "@/components/forms/TerminalConnectorForm.vue";
import TerminalScreensaversForm from "@/components/forms/TerminalScreensaversForm.vue";
import TerminalRegisterForm from "@/components/forms/TerminalRegisterForm.vue";
import ScreensaversText from "@/components/forms/ScreensaversText.vue";
import Translate from "@/components/Translate.vue";
import License from "@/components/License.vue";
import FooterText from "@/components/forms/FooterText.vue";
import ScreensaversInterval from "@/components/forms/ScreensaversInterval.vue";
import ArticleListForm from "@/components/forms/ArticleListForm.vue";
import GalleriesForm from "@/components/forms/GalleriesForm.vue";
import SmartSignageForm from "@/components/forms/SmartSignageForm.vue";
import ShippingCountriesForm from "@/components/forms/ShippingCountriesForm.vue";

export default {
    name: 'Admin',
    components: {
        ShippingCountriesForm,
        SmartSignageForm,
        GalleriesForm,
        ArticleListForm,
        ScreensaversInterval,
        FooterText,
        License,
        Translate,
        ScreensaversText,
        TerminalRegisterForm,
        TerminalScreensaversForm,
        TerminalConnectorForm,
        TerminalPinForm,
        MeasurementUnitForm,
        SocialMediaImageForm,
        LogoForm,
        FaviconForm,
        SMTPConfigForm,
        InfoBox,
        RoundButton,
        LabelSelect,
        CurrencyInput,
        Label,
        LabelWithError,
        LinkObject,
        SuccessMessage,
        ErrorMessage,
        ToggleItem,
        Modal,
        Description,
        TextField,
        Button,
        Icon,
        SwitchButton,
        Card,
        LoadingStruct,
        VueEditor,
    },
    data() {
        return {
            triggerName: 0,
            triggerEmail: 0,
            triggerPassword: 0,
            triggerServiceFee: 0,
            triggerCreateArticle: 0,
            triggerShippingGraduatedPrices: 0,
            triggerVat: 0,

            passwordFields: [
                {
                    value: "",
                    valid: false,

                    name: "password",
                    label: "password.current",
                    icon: "unlock",
                    rules: "required",
                    autocomplete: "password",
                    type: "password",
                },
                {
                    value: "",
                    valid: false,

                    name: "password-new",
                    label: "password.new",
                    icon: "lock",
                    rules: "required|min:8|max:4096",
                    autocomplete: "new-password",
                    type: "password",
                    hasPasswordMeter: true,
                },
            ],

            nameFields: [
                {
                    value: "",
                    valid: false,

                    name: "name",
                    label: "studio.name",
                    icon: "hashtag",
                    rules: "required|max:320",
                },
            ],

            serviceFeeWithDiscount: false,
            serviceFeeFields: [
                {
                    value: 0,
                    valid: true,

                    name: "fee",
                    label: "fee",
                    icon: "tag",
                    rules: "",
                    type: "number",
                    steps: 0.01,
                },
                {
                    value: 0,
                    valid: true,

                    name: "discount",
                    label: "Dropped.at",
                    icon: "tag",
                    rules: "",
                    type: "number",
                    steps: 0.01,
                },
            ],

            serviceFeeWithDiscountGallery: false,
            serviceFeeFieldsGallery: [
                {
                    value: 0,
                    valid: true,

                    name: "fee",
                    label: "fee",
                    icon: "tag",
                    rules: "",
                    type: "number",
                    steps: 0.01,
                },
                {
                    value: 0,
                    valid: true,

                    name: "discount",
                    label: "Dropped.at",
                    icon: "tag",
                    rules: "",
                    type: "number",
                    steps: 0.01,
                },
            ],

            serviceFeeWithDiscountTerminal: false,
            serviceFeeFieldsTerminal: [
                {
                    value: 0,
                    valid: true,

                    name: "fee",
                    label: "fee",
                    icon: "tag",
                    rules: "",
                    type: "number",
                    steps: 0.01,
                },
                {
                    value: 0,
                    valid: true,

                    name: "discount",
                    label: "Dropped.at",
                    icon: "tag",
                    rules: "",
                    type: "number",
                    steps: 0.01,
                },
            ],

            vatFields: [
                {
                    value: 0,
                    valid: true,

                    name: "vat",
                    label: "vat",
                    icon: "cart",
                    rules: "",
                    type: "number",
                    steps: 0.01,
                },
            ],

            priceWithMinPrice: false,
            createMinPriceFields: [
                {
                    value: 0.00,
                    valid: true,

                    name: "min-price",
                    label: "minimum.price",
                    icon: "tag",
                    rules: "required",
                    type: "number",
                    steps: 0.01,
                },
            ],


            priceWithMaxLength: false,
            maxLengthCreateFields: [
                {
                    value: 0,
                    valid: false,

                    name: "max-length",
                    label: "maximum.length",
                    labelInfo: "",
                    icon: "chevron-double-right",
                    rules: "required|integer|min_value:1",
                    type: "number",
                },
            ],


            createPriceFields: [
                {
                    value: 0.00,
                    valid: true,

                    name: "price",
                    label: "price",
                    icon: "tag",
                    rules: "required",
                    type: "number",
                    steps: 0.01,
                },
            ],

            createArticleFieldsOcId: [
                {
                    value: "",
                    valid: false,

                    name: "ocId",
                    label: "ocId",
                    icon: "code",
                    rules: "required|integer|between:1,300",
                    type: "number",
                },
            ],

            createArticleFieldName: [
                {
                    value: "",
                    valid: true,

                    name: "name",
                    label: "name",
                    icon: "tag",
                    rules: "max:23",
                    type: "text",
                },
            ],

            createArticleFieldsNoritsu: [
                {
                    value: "",
                    valid: false,

                    name: "noritsuID",
                    label: "noritsuID",
                    icon: "code",
                    rules: "required|integer|between:1,300",
                    type: "number",
                },
            ],

            createArticleFieldsDPI: [
                {
                    value: 72,
                    valid: true,

                    name: "printer.dpi",
                    label: "printer.dpi",
                    icon: "crosshairs",
                    rules: "required|integer|min_value:1",
                    type: "number",
                },
            ],


            createArticleFields: [
                {
                    value: "",
                    valid: false,

                    name: "width",
                    label: "width",
                    labelInfo: "",
                    icon: " arrow-up",
                    rules: "required|min_value:1",
                    type: "number",
                },
                {
                    value: "",
                    valid: false,

                    name: "height",
                    label: "height",
                    labelInfo: "",
                    icon: "arrow-right",
                    rules: "required|min_value:1",
                    type: "number",
                },
            ],

            selectedSuggestedPaper: null,
            createArticlePaperFields: [
                {
                    value: "",
                    valid: false,

                    name: "paper",
                    label: "paper",
                    icon: "file",
                    rules: "required|filename|max:23",
                    autocomplete: "paper",
                },
            ],


            createArticleActive: true,
            createArticleFavorite: false,
            createArticleTerminal: false,
            createArticleGraduatedPrices: [],
            createArticleMirage: null,
            createArticleMirageError: null,
            createArticleType: "",

            createArticleVariableLength: false,

            shippingGraduatedPrices: [],

            mirageError: null,

            hoveredArticleId: null,
            selectedArticle: null,
            selectedArticleClone: false,
            modalIdArticle: 'article-details',
            modalIdArticleDelete: 'article-delete',

            listenerInited: false,

            emailFields: [
                {
                    value: "",
                    valid: true,

                    name: "email",
                    label: "emailAddress",
                    icon: "mail",
                    rules: "required|emailV2",
                },
            ],

            loading: true,
            error: null,

            fee: 0.00,
            currency: "",
            paymentMethods: [],

            tabs: [
                'articles',
                'gallery',
                'payment.settings',
                'design',
                'white.label',
                'terminal',
                // 'smart.signage',
                'cloud.connector',
                'system',
            ],

            cloudConnectors: [
                {
                    type: "windows",
                    file: "win.zip"
                },
                {
                    type: "mac",
                    file: "mac.zip"
                },
            ],

            ocIds: [],

            settings: {
                name: "",
                email: "",

                siteNotice: "",
                dataPrivacy: "",
                emailSignature: "",
                termsAndConditions: "",


                pictures: [],

                articles: [
                    {
                        id: "",
                        paper: "",
                        width: 0,
                        height: 0,
                        price: 0.0,
                        ocId: 0,
                        active: true,
                        graduatedPrices: [
                            {
                                price: 0.0,
                                amount: 0,
                            }
                        ]
                    },
                ],

                currency: "",

                suggestedPapers: [],
                allowedCurrencies: [],
            },

            allowedPaymentMethods: [],
            allowedPaymentMethodsGallery: [],
            allowedPaymentMethodsTerminal: [],
            allowedConfigurableMandatoryFields: [],
            errorPaymentMethodsInput: null,

            articleColumns: ["width.x.height", "paper", "price", "graduated.prices"],
            graduatedPriceColumns: ["amount", "price"],


            pictures: [],

            errorReset: null,
            loadingReset: false,
            successReset: false,

            errorEmail: null,
            loadingEmail: false,
            successEmail: false,

            errorName: null,
            loadingName: false,
            successName: false,

            errorServiceFee: null,
            loadingServiceFee: false,
            successServiceFee: false,

            errorVat: null,
            loadingVat: false,
            successVat: false,

            errorCreateArticle: null,
            loadingCreateArticle: false,
            successCreateArticle: false,

            errorCurrency: null,
            loadingCurrency: false,
            successCurrency: false,

            errorPaymentMethods: null,
            loadingPaymentMethods: false,
            successPaymentMethods: false,

            errorShippingGraduatedPrices: null,
            loadingShippingGraduatedPrices: false,
            successShippingGraduatedPrices: false,

            code: null,
            codeValue: null,
            codeRegened: false,
            errorCode: null,
            loadingCode: false,
            successCode: false,

            errorPassword: null,
            loadingPassword: false,
            successPassword: false,

            errorLanguage: null,
            loadingLanguage: false,
            successLanguage: false,

            errorMandatoryFields: null,
            loadingMandatoryFields: false,
            successMandatoryFields: false,

            range: {
                start: new Date(new Date().setHours(0, 0, 0, 0)),
                end: new Date(new Date().setHours(23, 59, 59, 0)),
            },
            absenceActive: false,
            errorAbsence: null,
            loadingAbsence: false,
            successAbsence: false,

            errorDataPrivacy: null,
            loadingDataPrivacy: false,
            successDataPrivacy: false,

            errorEmailSignature: null,
            loadingEmailSignature: false,
            successEmailSignature: false,

            errorSiteNotice: null,
            loadingSiteNotice: false,
            successSiteNotice: false,

            errorTermsAndConditions: null,
            loadingTermsAndConditions: false,
            successTermsAndConditions: false,

            errorArticles: null,
            loadingArticles: false,
            successArticles: false,

            loadingDelete: false,


            errorPictures: null,
            loadingPictures: false,
            successPictures: false,

            picturesError: null,

            documentMeta: [],

            supportLink: "https://phy-prints.com/version.php",
            qrCodeLink: "https://phy-prints.com/qr_code_gen.php?domain="
        }
    },
    mounted() {
        window.addEventListener('dragover', this.dragoverListener, false);
    },
    created() {
        dayjs.locale(this.$i18n.locale);
        this.receiveSettings();
    },
    computed: {
        isTerminalEnabled() {
            return this.settings.modules && this.settings.modules.includes('terminal');
        },
        isGalleryEnabled() {
            return this.settings.modules && this.settings.modules.includes('gallery');
        },
        allowedSizeScreensavers() {
            if (this.settings.maxFileSizeForScreensaversInMB) {
                return this.settings.maxFileSizeForScreensaversInMB;
            }

            return 0;
        },
        allowedFaviconSize() {
            if (this.settings.maxFileSizeForBackgroundPicturesInMB) {
                return this.settings.maxFileSizeForBackgroundPicturesInMB;
            }

            return 0;
        },
        allowedFaviconExtensions() {
            if (this.settings.allowedFaviconExtensions) {
                return this.settings.allowedFaviconExtensions;
            }

            return [];
        },
        fileExtensionsBackground() {
            if (this.settings.allowedFileExtensions) {
                return this.settings.allowedFileExtensions;
            }

            return [];
        },
        fileExtensionsSocialMediaImage() {
            if (this.getInfos.allowedFileExtensions) {
                return this.getInfos.allowedFileExtensions;
            }

            return [];
        },
        fileExtensionsScreensavers() {
            if (this.settings.allowedFileExtensionsScreensavers) {
                return this.settings.allowedFileExtensionsScreensavers;
            }

            return [];
        },
        getMaxMirageFileSizeInMB() {
            return this.settings.allowedMirageFileSizeInKB;
        },
        getComputedMirageFileExtensions() {
            if (!this.settings.allowedMirageFileExtensions) {
                return [];
            }

            const lower = this.settings.allowedMirageFileExtensions.map(element => {
                return element.toLowerCase();
            });

            return [...new Set(lower)];
        },
        getComputedCurrencyOptions() {
            return {
                "currency": this.getInfos.currency,
                "currencyDisplay": "symbol",
                "valueRange": {
                    "min": 0
                },
                "precision": 2,
                "hideCurrencySymbolOnFocus": false,
                "hideGroupingSeparatorOnFocus": false,
                "hideNegligibleDecimalDigitsOnFocus": false,
                "autoDecimalDigits": false,
                "useGrouping": true,
                "accountingSign": false
            };
        },
        getComputedCurrencyOptionsGraduated() {
            return {
                "currency": this.getInfos.currency,
                "currencyDisplay": "symbol",
                "valueRange": {
                    "min": 0
                },
                "precision": {"min": 2, "max": 3},
                "hideCurrencySymbolOnFocus": false,
                "hideGroupingSeparatorOnFocus": false,
                "hideNegligibleDecimalDigitsOnFocus": false,
                "autoDecimalDigits": false,
                "useGrouping": true,
                "accountingSign": false
            };
        },
        computedArticles() {
            let articles = this.settings.articles;

            if (!articles) {
                return [];
            }

            articles = articles.sort((a, b) => Number(b.isFavorite) - Number(a.isFavorite));
            articles = articles.sort((a, b) => this.getPaperName(a.paper).localeCompare(this.getPaperName(b.paper)));

            function sortNodesHeight(a, b) {
                if (a.height < b.height) return -1;
                if (a.height > b.height) return 1;
                return 0;
            }

            function sortNodesWidth(a, b) {
                if (a.width < b.width) return -1;
                if (a.width > b.width) return 1;
                return 0;
            }

            articles = articles.sort(sortNodesHeight);

            return articles.sort(sortNodesWidth)
        },
        createArticleActivateError() {
            if (this.createArticleType === 'mirage' && !this.createArticleMirage) {
                return this.$t('mirage.file.required');
            }

            if (this.createArticleType === "noritsu"
                && this.createArticleFieldsNoritsu[0]["value"]
                && (this.createArticleFieldsNoritsu[0]["value"] < 1 || this.createArticleFieldsNoritsu[0]["value"] > 300)
            ) {
                return this.$t('type.id.range.message', {type: this.$t('noritsuID')});
            }

            if (this.createArticleType === "orderControl"
                && this.createArticleFieldsOcId[0]["value"]
                && (this.createArticleFieldsOcId[0]["value"] < 1 || this.createArticleFieldsOcId[0]["value"] > 300)
            ) {
                return this.$t('type.id.range.message', {type: this.$t('ocId')});
            }

            return "";
        },
        createArticleActivateDisabled() {
            return !!this.createArticleActivateError;
        },
    },
    methods: {
        articleSwitchButtonDisabled(article) {
            if (article['type'] === 'orderControl' && article['ocId'] && (article['ocId'] < 1 || article['ocId'] > 300)) {
                return true;
            }

            if (article['type'] === 'noritsu' && article['noritsuID'] && (article['noritsuID'] < 1 || article['noritsuID'] > 300)) {
                return true;
            }

            if (article['type'] === 'mirage' && !article['mirageFile']) {
                return true;
            }

            return false;
        },
        articleSwitchButtonTooltip(article) {
            if (article['type'] === 'orderControl' && article['ocId'] && (article['ocId'] < 1 || article['ocId'] > 300)) {
                return this.$t('type.id.range.message', {type: this.$t('ocId')}) + '.';
            }

            if (article['type'] === 'noritsu' && article['noritsuID'] && (article['noritsuID'] < 1 || article['noritsuID'] > 300)) {
                return this.$t('type.id.range.message', {type: this.$t('noritsuID')}) + '.';
            }

            if (article['type'] === 'mirage' && !article['mirageFile']) {
                return this.$t('mirage.file.required') + '.';
            }

            if (article['active']) {
                this.$t('deactivate');
            }

            return this.$t('activate');
        },
        addGraduatedShipping() {
            this.successShippingGraduatedPrices = false;

            if (!this.shippingGraduatedPrices) {
                this.shippingGraduatedPrices = [];
            }

            let newStruct = [
                {
                    value: "",
                    valid: false,

                    name: "at.amount",
                    label: "at.amount",
                    icon: "hashtag",
                    rules: "required|min_value:0",
                    type: "number",
                },
                {
                    value: 0,
                    valid: true,

                    name: "price",
                    label: "price",
                    icon: "tag",
                    rules: "required",
                    type: "number",
                    steps: 0.01,
                },
            ];

            this.shippingGraduatedPrices.push(newStruct);

            this.$forceUpdate();
        },
        articleChangeHandler() {
            this.createArticleFieldsOcId[0].valid = false

            this.createArticleFieldsNoritsu[0].valid = false

            if (this.createArticleType === 'mirage' && (!this.selectedArticle || !this.selectedArticle.mirageFile)) {
                this.createArticleActive = false;
            }
        },
        dragoverListener(e) {
            e.preventDefault();

            let element = document.getElementById('mirage-upload');
            if (element) {
                element.classList.add('dragover');
            }
        },
        dropListener(e) {
            e.preventDefault();

            let element = document.getElementById('mirage-upload');
            if (element) {
                element.classList.remove('dragover');
            }

            let files = e.dataTransfer.files;

            if (files[0].type === "") {
                this.createArticleMirageError = this.$t("invalid.app.file.drop.message");
                return;
            }

            let fileExtension = files[0].name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();

            if (!this.getComputedMirageFileExtensions.includes(fileExtension)) {
                this.createArticleMirageError = this.$t("file.type.error", {
                    name: this.$t('mirage.file'),
                    types: this.getFormattedTypesString(this.getComputedMirageFileExtensions)
                });

                return;
            }

            if (files[0].size > (this.getMaxMirageFileSizeInMB * 1024)) {
                this.createArticleMirageError = this.$t("invalid.mirage.file");
                return;
            }

            this.createArticleMirage = files[0];
            if (!this.selectedArticle || (this.selectedArticle && this.selectedArticle.active)) {
                this.createArticleActive = true;
            }
        },
        getFirstModule() {
            if (this.settings.processingModules && this.settings.processingModules[0]) {
                return this.settings.processingModules[0];
            }

            return "";
        },
        newArticle() {
            this.selectedArticle = null;
            this.selectedArticleClone = false;
            this.createArticleGraduatedPrices = [];
            this.createArticleActive = true;
            this.createArticleFavorite = false;
            this.createArticleTerminal = false;
            this.createArticleMirage = null;
            this.createArticleType = this.getFirstModule();

            let ocIds = [];
            if (this.settings.articles && this.settings.articles.length > 0) {
                let articles = this.settings.articles;
                for (let i = 0, j = articles.length; i < j; i++) {
                    if (!articles[i].ocId) {
                        continue;
                    }

                    if (articles[i].isTerminal) {
                        continue;
                    }

                    ocIds.push(articles[i].ocId);
                }
            }

            let noritsuIDs = [];
            if (this.settings.articles && this.settings.articles.length > 0) {
                let articles = this.settings.articles;
                for (let i = 0, j = articles.length; i < j; i++) {
                    if (!articles[i].noritsuID) {
                        continue;
                    }

                    if (articles[i].isTerminal) {
                        continue;
                    }

                    noritsuIDs.push(articles[i].noritsuID);
                }
            }

            this.createArticleFieldsOcId[0].valid = false;
            this.createArticleFieldsOcId[0].value = "";
            this.createArticleFieldsOcId[0].rules = "required|integer|min_value:1|max_value:300|unique:" + ocIds.join(",");

            this.createArticleFieldsNoritsu[0].valid = false;
            this.createArticleFieldsNoritsu[0].value = "";
            this.createArticleFieldsNoritsu[0].rules = "required|integer|min_value:1|max_value:300|unique:" + noritsuIDs.join(",");

            for (let i = 0, j = this.createArticleFields.length; i < j; i++) {
                this.createArticleFields[i].value = "";
                this.createArticleFields[i].valid = false;
            }

            for (let i = 0, j = this.createArticlePaperFields.length; i < j; i++) {
                this.createArticlePaperFields[i].value = "";
                this.createArticlePaperFields[i].valid = false;
            }

            for (let i = 0, j = this.createPriceFields.length; i < j; i++) {
                this.createPriceFields[i].value = 0;
                this.createPriceFields[i].valid = true;
            }


            for (let i = 0, j = this.createArticleFieldsDPI.length; i < j; i++) {
                this.createArticleFieldsDPI[i].value = 72;
                this.createArticleFieldsDPI[i].valid = true;
            }

            this.createArticleVariableLength = false;
            this.variableLengthHandler(false);

            this.priceWithMinPrice = false;
            for (let i = 0, j = this.createPriceFields.length; i < j; i++) {
                this.createMinPriceFields[i].value = 0.00;
                this.createMinPriceFields[i].valid = true;
            }

            this.priceWithMaxLength = false;
            for (let i = 0, j = this.maxLengthCreateFields.length; i < j; i++) {
                this.createMinPriceFields[i].value = 0;
                this.createMinPriceFields[i].valid = true;
            }

            for (let i = 0, j = this.createArticleFieldName.length; i < j; i++) {
                this.createArticleFieldName[i].value = "";
                this.createArticleFieldName[i].valid = true;
            }

            UIkit.modal("#" + this.modalIdArticle).show();

            if (!this.listenerInited) {
                let self = this;
                setTimeout(function () {
                    let element = document.getElementById('mirage-upload');
                    element.addEventListener('drop', self.dropListener, false);
                }, 50);

                this.listenerInited = true;
            }

            UIkit.scroll("#modal-title", {offset: 0}).scrollTo("#modal-title");
        },
        selectArticle(article, clone = false) {
            this.selectedArticle = article;
            this.selectedArticleClone = clone;

            if (clone) {
                for (let i = 0, j = this.createArticleFieldsOcId.length; i < j; i++) {
                    this.createArticleFieldsOcId[i].valid = false;
                }

                for (let i = 0, j = this.createArticleFieldsNoritsu.length; i < j; i++) {
                    this.createArticleFieldsNoritsu[i].valid = false;
                }

                this.createArticleFieldsOcId[0].value = "";
                this.createArticleFieldsNoritsu[0].value = "";

            } else {
                for (let i = 0, j = this.createArticleFieldsOcId.length; i < j; i++) {
                    this.createArticleFieldsOcId[i].valid = true;
                }

                for (let i = 0, j = this.createArticleFieldsNoritsu.length; i < j; i++) {
                    this.createArticleFieldsNoritsu[i].valid = true;
                }

                this.createArticleFieldsOcId[0].value = article.ocId;
                this.createArticleFieldsNoritsu[0].value = article.noritsuID;

            }

            for (let i = 0, j = this.createArticleFields.length; i < j; i++) {
                this.createArticleFields[i].valid = true;
            }

            this.createArticleFields[0].value = article.width;
            this.createArticleFields[1].value = article.height;
            this.createPriceFields[0].value = article.price;

            let self = this;
            setTimeout(function () {
                self.triggerCreateArticle++;
            }, 50);


            let targetPaper = article.paper;
            if (['matt', 'glossy'].includes(targetPaper)) {
                this.selectedSuggestedPaper = article.paper;
                targetPaper = this.$t(article.paper);
            }

            this.createArticlePaperFields[0].value = targetPaper;
            this.createArticleActive = article.active;
            this.createArticleFavorite = article.isFavorite;
            if (this.selectedArticleClone) {
                this.createArticleFavorite = false;
            }
            this.createArticleTerminal = article.isTerminal;
            this.createArticleType = article.type;

            this.createArticleMirage = null;
            if (article.type === 'mirage' && article.mirageFile) {
                this.createArticleMirage = this.getFileName(article.mirageFile);

                if (clone) {
                    this.selectedArticle.mirageFile = null;
                    this.fetchMirageFile();
                }
            }

            this.createArticleGraduatedPrices = [];
            if (article.graduatedPrices) {
                for (let i = 0, j = article.graduatedPrices.length; i < j; i++) {
                    let newStruct = [
                        {
                            value: article.graduatedPrices[i]['amount'],
                            valid: true,

                            name: "at.amount",
                            label: "at.amount",
                            icon: "hashtag",
                            rules: "required|min_value:0",
                            type: "number",
                        },
                        {
                            value: article.graduatedPrices[i]['price'],
                            valid: true,

                            name: "price",
                            label: "price",
                            icon: "tag",
                            rules: "required",
                            type: "number",
                            steps: 0.01,
                        },
                    ];

                    this.createArticleGraduatedPrices.push(newStruct);
                }
            }

            let articles = this.settings.articles;

            let ocIds = [];
            for (let i = 0, j = articles.length; i < j; i++) {
                if (!articles[i].ocId) {
                    continue;
                }

                if (!this.selectedArticleClone && articles[i].ocId === article.ocId) {
                    continue;
                }

                if (article.isTerminal && !articles[i].isTerminal) {
                    continue;
                }
                if (!article.isTerminal && articles[i].isTerminal) {
                    continue;
                }

                ocIds.push(articles[i].ocId);
            }
            this.createArticleFieldsOcId[0].rules = "required|integer|min_value:1|max_value:300|unique:" + ocIds.join(",");

            let noritsuIDs = [];
            for (let i = 0, j = articles.length; i < j; i++) {
                if (!articles[i].noritsuID) {
                    continue;
                }

                if (!this.selectedArticleClone && articles[i].noritsuID === article.noritsuID) {
                    continue;
                }

                if (article.isTerminal && !articles[i].isTerminal) {
                    continue;
                }
                if (!article.isTerminal && articles[i].isTerminal) {
                    continue;
                }

                noritsuIDs.push(articles[i].noritsuID);
            }
            this.createArticleFieldsNoritsu[0].rules = "required|integer|min_value:1|max_value:300|unique:" + noritsuIDs.join(",");


            if (article.dpi) {
                this.createArticleFieldsDPI[0].value = article.dpi;
                this.createArticleFieldsDPI[0].valid = true;
            } else {
                this.createArticleFieldsDPI[0].value = 72;
                this.createArticleFieldsDPI[0].valid = true;
            }


            if (article.name) {
                this.createArticleFieldName[0].value = article.name;
                this.createArticleFieldName[0].valid = true;
            } else {
                this.createArticleFieldName[0].value = "";
                this.createArticleFieldName[0].valid = true;
            }

            this.createArticleVariableLength = article.isHeightVariable;
            this.variableLengthHandler(article.isHeightVariable);


            if (article.minPrice) {
                this.priceWithMinPrice = true;
                this.createMinPriceFields[0].value = article.minPrice;
                this.createMinPriceFields[0].valid = true;
            } else {
                this.priceWithMinPrice = false;
                this.createMinPriceFields[0].value = 0.00;
                this.createMinPriceFields[0].valid = true;
            }

            if (article.maxLength) {
                this.priceWithMaxLength = true;
                this.maxLengthCreateFields[0].value = article.maxLength;
                this.maxLengthCreateFields[0].valid = true;
            } else {
                this.priceWithMaxLength = false;
                this.maxLengthCreateFields[0].value = 0;
                this.maxLengthCreateFields[0].valid = false;
            }

            UIkit.modal("#" + this.modalIdArticle).show();

            if (!this.listenerInited) {
                let self = this;
                setTimeout(function () {
                    let element = document.getElementById('mirage-upload');
                    element.addEventListener('drop', self.dropListener, false);
                }, 50);

                this.listenerInited = true;
            }

            UIkit.scroll("#modal-title", {offset: 0}).scrollTo("#modal-title");
        },
        getFileName(rawFileName) {
            let parts = rawFileName.split("_");
            return parts[1];
        },
        receiveSettings() {
            let http = axios.create();
            http.get(this.apiUrl + '/settings').then(response => {
                let settings = response.data;

                this.nameFields[0].value = settings.name;
                if (settings.name) {
                    this.nameFields[0].valid = true;
                }

                this.emailFields[0].value = settings.email;
                this.vatFields[0].value = settings.vat;

                this.serviceFeeFields[0].value = settings.fee;
                if (settings.feeDiscount != null) {
                    this.serviceFeeWithDiscount = true;
                    this.serviceFeeFields[1].value = settings.feeDiscount;
                }

                this.serviceFeeFieldsGallery[0].value = settings.gallery.fee;
                if (settings.gallery.feeDiscount != null) {
                    this.serviceFeeWithDiscountGallery = true;
                    this.serviceFeeFieldsGallery[1].value = settings.gallery.feeDiscount;
                }

                this.serviceFeeFieldsTerminal[0].value = settings.terminal.fee;
                if (settings.terminal.feeDiscount != null) {
                    this.serviceFeeWithDiscountTerminal = true;
                    this.serviceFeeFieldsTerminal[1].value = settings.terminal.feeDiscount;
                }

                this.currency = settings.currency;

                for (let i = settings.pictures.length, j = this.settings.allowedBackgroundPictures; i < j; i++) {
                    this.settings.pictures.push(null);
                }

                for (let i = settings.screensavers.length, j = this.settings.allowedBackgroundPictures; i < j; i++) {
                    this.settings.screensavers.push(null);
                }

                if (!settings.paymentMethods) {
                    settings.paymentMethods = [];
                }

                this.allowedPaymentMethods = [];
                for (let i = 0, j = settings.allowedPaymentMethods.length; i < j; i++) {
                    this.allowedPaymentMethods.push({
                        name: settings.allowedPaymentMethods[i],
                        value: settings.paymentMethods.includes(settings.allowedPaymentMethods[i]),
                    });
                }

                this.allowedPaymentMethodsGallery = [];
                for (let i = 0, j = settings.allowedPaymentMethods.length; i < j; i++) {
                    this.allowedPaymentMethodsGallery.push({
                        name: settings.allowedPaymentMethods[i],
                        value: settings.gallery.paymentMethods.includes(settings.allowedPaymentMethods[i]),
                    });
                }

                this.allowedPaymentMethodsTerminal = [];
                for (let i = 0, j = settings.allowedPaymentMethods.length; i < j; i++) {
                    this.allowedPaymentMethodsTerminal.push({
                        name: settings.allowedPaymentMethods[i],
                        value: settings.terminal.paymentMethods.includes(settings.allowedPaymentMethods[i]),
                    });
                }

                this.allowedConfigurableMandatoryFields = [];
                if (settings.allowedConfigurableMandatoryFields) {
                    for (let i = 0, j = settings.allowedConfigurableMandatoryFields.length; i < j; i++) {
                        let val = false;
                        if (settings.configurableMandatoryFields && settings.configurableMandatoryFields.includes(settings.allowedConfigurableMandatoryFields[i])) {
                            val = true;
                        }

                        this.allowedConfigurableMandatoryFields.push({
                            name: settings.allowedConfigurableMandatoryFields[i],
                            value: val,
                        });
                    }
                }

                let ocIds = [];
                if (settings.articles) {
                    let articles = settings.articles;
                    for (let i = 0, j = articles.length; i < j; i++) {
                        if (!articles[i].ocId) {
                            continue;
                        }

                        ocIds.push(articles[i].ocId);
                    }
                }

                this.createArticleFieldsOcId[0].rules = "required|integer|min_value:1|max_value:300|unique:" + ocIds.join(",");

                let noritsuIDs = [];
                if (settings.articles) {
                    let articles = settings.articles;
                    for (let i = 0, j = articles.length; i < j; i++) {
                        if (!articles[i].noritsuID) {
                            continue;
                        }

                        noritsuIDs.push(articles[i].noritsuIDs);
                    }
                }

                this.createArticleFieldsNoritsu[0].rules = "required|integer|min_value:1|max_value:300|unique:" + noritsuIDs.join(",");

                this.shippingGraduatedPrices = [];
                if (settings.shippingGraduatedPrices) {
                    let graduatedPricesParsed = JSON.parse(JSON.stringify(settings.shippingGraduatedPrices));

                    // eslint-disable-next-line no-inner-declarations
                    function sortNodes(a, b) {
                        if (a.amount < b.amount) return -1;
                        if (a.amount > b.amount) return 1;
                        return 0;
                    }

                    graduatedPricesParsed = graduatedPricesParsed.sort(sortNodes);

                    for (let i = 0, j = graduatedPricesParsed.length; i < j; i++) {
                        let newStruct = [
                            {
                                value: graduatedPricesParsed[i]['amount'],
                                valid: true,

                                name: "at.amount",
                                label: "at.amount",
                                icon: "hashtag",
                                rules: "required|min_value:0",
                                type: "number",
                            },
                            {
                                value: graduatedPricesParsed[i]['price'],
                                valid: true,

                                name: "price",
                                label: "price",
                                icon: "tag",
                                rules: "required",
                                type: "number",
                                steps: 0.01,
                            },
                        ];

                        this.shippingGraduatedPrices.push(newStruct);
                    }
                }

                if (settings.absence && settings.absence.start && settings.absence.end) {
                    this.absenceActive = true;
                    this.range = {
                        start: settings.absence.start,
                        end: settings.absence.end,
                    };
                }

                this.settings = settings;

                this.createArticleFields[0].labelInfo = " " + this.$t('in.x', {x: this.$t(settings.measurementUnit)});
                this.createArticleFields[1].labelInfo = " " + this.$t('in.x', {x: this.$t(settings.measurementUnit)});
                this.maxLengthCreateFields[0].labelInfo = " " + this.$t('in.x', {x: this.$t(settings.measurementUnit)});

                this.error = null;
                this.loading = false;

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.loading = false;

            });
        },
        filePicturesDelete(key) {
            this.successPictures = false;
            Vue.set(this.settings.pictures, key, null);
            Vue.set(this.pictures, key, null);
        },
        filePicturesHandle(key) {
            let file = this.$refs['filePicture' + key][0].files[0];

            let fileExtension = file.name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();
            if (!this.fileExtensionsBackground.includes(fileExtension)) {
                this.picturesError = this.$t("files.type.error", {
                    name: this.$t('background.pictures'),
                    types: this.getFormattedTypesString(this.fileExtensionsBackground)
                });

                return;
            }

            if (file.size > (this.settings.maxFileSizeForBackgroundPicturesInMB * 1024 * 1024)) {
                this.picturesError = this.picturesError = this.$t("files.size.error", {
                    name: this.$t('background.pictures'),
                    mb: this.settings.maxFileSizeForBackgroundPicturesInMB
                });
                return;
            }

            this.picturesError = null;
            this.successPictures = false;
            Vue.set(this.pictures, key, file);
        },
        openSupportLink() {
            event.stopPropagation();
            event.preventDefault();
            window.open(this.supportLink, '_blank').focus();
        },
        generateQRCode() {
            return this.qrCodeLink + window.location.origin;
        },
        openQRCodeLink() {
            event.stopPropagation();
            event.preventDefault();
            window.open(this.generateQRCode(), '_blank').focus();
        },
        showSMTPConfig() {
            this.setHashtag("white.label");
            UIkit.tab("#tabs").show(this.tabs.length - 1);

            setTimeout(function () {
                let elementId = "smtp-config";
                UIkit.scroll("#" + elementId, {offset: 0}).scrollTo("#" + elementId);
            }, 250);
        },
        downloadDocument(doc) {
            event.preventDefault();
            let self = this;

            Vue.set(self.documentMeta, doc, true);

            let request = new XMLHttpRequest();
            request.open("GET", this.apiUrl + '/docs/' + doc, true);
            request.responseType = "blob";
            request.onload = function () {
                let blob = request.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = doc;
                link.click();

                Vue.set(self.documentMeta, doc, false);
            };

            request.setRequestHeader("token", VueCookie.get("token"));
            request.send();
        },
        setPasswordValid(valid, fieldKey) {
            this.successPassword = false;
            this.passwordFields[fieldKey].valid = valid
        },
        setNameValid(valid, fieldKey) {
            this.successName = false;
            this.nameFields[fieldKey].valid = valid
        },
        setCreateArticleValid(valid, fieldKey) {
            this.successCreateArticle = false;
            this.createArticleFields[fieldKey].valid = valid
        },
        setCreateArticleOcIdValid(valid, fieldKey) {
            this.successCreateArticle = false;
            this.createArticleFieldsOcId[fieldKey].valid = valid
        },
        setCreateArticleNameValid(valid, fieldKey) {
            this.successCreateArticle = false;
            this.createArticleFieldName[fieldKey].valid = valid
        },
        setCreateArticleNoritsuIDValid(valid, fieldKey) {
            this.successCreateArticle = false;
            this.createArticleFieldsNoritsu[fieldKey].valid = valid
        },
        setCreateArticleDPIValid(valid, fieldKey) {
            this.successCreateArticle = false;
            this.createArticleFieldsDPI[fieldKey].valid = valid
        },
        setCreateArticleMaxLengthValid(valid, fieldKey) {
            this.successCreateArticle = false;
            this.maxLengthCreateFields[fieldKey].valid = valid
        },
        setCreateArticlePaperValid(valid, fieldKey) {
            this.successCreateArticle = false;
            this.createArticlePaperFields[fieldKey].valid = valid
        },
        setCreateArticleGraduatesPricesValid(valid, fieldKey, identifier) {
            this.successCreateArticle = false;
            this.createArticleGraduatedPrices[identifier][fieldKey].valid = valid
        },
        setShippingGraduatedPricesValid(valid, fieldKey, identifier) {
            this.successShippingGraduatedPrices = false;
            this.shippingGraduatedPrices[identifier][fieldKey].valid = valid
        },
        setEmailValid(valid, fieldKey) {
            this.successEmail = false;
            this.emailFields[fieldKey].valid = valid
        },
        isTerminalHandler(active) {
            let articles = this.settings.articles;
            let article = this.selectedArticle;

            let ocIds = [];
            for (let i = 0, j = articles.length; i < j; i++) {
                if (!articles[i].ocId) {
                    continue;
                }

                if (article && !this.selectedArticleClone && articles[i].ocId === article.ocId) {
                    continue;
                }

                if (active && !articles[i].isTerminal) {
                    continue;
                }

                if (!active && articles[i].isTerminal) {
                    continue;
                }

                ocIds.push(articles[i].ocId);
            }
            this.createArticleFieldsOcId[0].rules = "required|integer|min_value:1|max_value:300|unique:" + ocIds.join(",");

            let noritsuIDs = [];
            for (let i = 0, j = articles.length; i < j; i++) {
                if (!articles[i].noritsuID) {
                    continue;
                }

                if (article && !this.selectedArticleClone && articles[i].noritsuID === article.noritsuID) {
                    continue;
                }

                if (active && !articles[i].isTerminal) {
                    continue;
                }

                if (!active && articles[i].isTerminal) {
                    continue;
                }

                noritsuIDs.push(articles[i].noritsuID);
            }
            this.createArticleFieldsNoritsu[0].rules = "required|integer|min_value:1|max_value:300|unique:" + noritsuIDs.join(",");

            let self = this;
            setTimeout(function () {
                self.triggerCreateArticle++;
            }, 50);
        },
        variableLengthHandler(active) {
            if (active) {
                this.createPriceFields[0].labelInfo = " " + this.$t('in.x', {x: this.$t(this.settings.measurementUnit)});
                this.createArticleFields[1].disabled = true;
                this.createArticleFields[1].overlay = this.$t('Variable');

                if (!this.createArticleFields[1].value) {
                    this.createArticleFields[1].value = 0;
                    this.createArticleFields[1].valid = true;
                }

                for (let i = 0, j = this.createArticleGraduatedPrices.length; i < j; i++) {
                    this.createArticleGraduatedPrices[i][1].labelInfo = " " + this.$t('in.x', {x: this.$t(this.settings.measurementUnit)});
                }

            } else {
                this.createPriceFields[0].labelInfo = ''
                this.createArticleFields[1].disabled = false;
                this.createArticleFields[1].overlay = null;

                for (let i = 0, j = this.createArticleGraduatedPrices.length; i < j; i++) {
                    this.createArticleGraduatedPrices[i][1].labelInfo = '';
                }

                if (this.createArticleFields[1].value === 0) {
                    this.createArticleFields[1].value = "";
                    this.createArticleFields[1].valid = false;
                }

            }
        },
        addGraduatedPrice() {
            this.successCreateArticle = false;

            if (!this.createArticleGraduatedPrices) {
                this.createArticleGraduatedPrices = [];
            }


            let newStruct = [
                {
                    value: "",
                    valid: false,

                    name: "at.amount",
                    label: "at.amount",
                    icon: "hashtag",
                    rules: "required|min_value:0",
                    type: "number",
                },
                {
                    value: 0,
                    valid: true,

                    name: "price",
                    label: "price",
                    labelInfo: this.createArticleVariableLength && this.settings ? " " + this.$t('in.x', {x: this.$t(this.settings.measurementUnit)}) : "",
                    icon: "tag",
                    rules: "required",
                    type: "number",
                    steps: 0.01,
                },
            ];

            this.createArticleGraduatedPrices.push(newStruct);

            this.$forceUpdate();
        },
        removeArticle(article) {
            this.selectedArticle = article;
            UIkit.modal("#" + this.modalIdArticleDelete).show();
        },
        cloneArticle(article) {
            this.selectArticle(article, true);
        },
        fetchMirageFile() {
            let self = this;
            axios({
                method: 'get',
                url: this.apiUrl + '/articles/' + self.selectedArticle.id + '/mirage-file',
                responseType: 'blob',

            }).then(function (response) {
                let file = new File([response.data], self.createArticleMirage);
                self.createArticleMirage = file;

            })
        },
        removeGraduatedPrice(key) {
            this.createArticleGraduatedPrices.splice(key, 1);
        },
        removeShippingGraduatedPrice(key) {
            this.shippingGraduatedPrices.splice(key, 1);
        },
        notify(message) {
            this.receiveInfos();

            UIkit.notification({
                message: message,
                status: 'info',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });
        },
        notifyChanged(x) {
            this.notify(this.$t('x.changes.saved', {x: x}));
        },
        savePassword() {
            this.triggerPassword++;

            for (let i = 0, j = this.passwordFields.length; i < j; i++) {
                if (!this.passwordFields[i].valid) {
                    return;
                }
            }

            this.loadingPassword = true;

            let http = axios.create();
            http.put(this.apiUrl + '/password', {
                currentPassword: this.passwordFields[0].value,
                newPassword: this.passwordFields[1].value
            }).then(() => {
                this.errorPassword = null;
                this.loadingPassword = false;
                this.successPassword = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorPassword = e;
                this.loadingPassword = false;
                this.successPassword = false;

            });
        },
        saveEmail() {
            this.triggerEmail++;

            for (let i = 0, j = this.emailFields.length; i < j; i++) {
                if (!this.emailFields[i].valid) {
                    return;
                }
            }

            this.loadingEmail = true;

            let http = axios.create();
            http.put(this.apiUrl + '/email', {email: this.emailFields[0].value}).then(() => {
                this.errorEmail = null;
                this.loadingEmail = false;
                this.successEmail = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorEmail = e;
                this.loadingEmail = false;

            });
        },
        saveName() {
            this.triggerName++;

            for (let i = 0, j = this.nameFields.length; i < j; i++) {
                if (!this.nameFields[i].valid) {
                    return;
                }
            }

            this.loadingName = true;

            let http = axios.create();
            http.put(this.apiUrl + '/name', {name: this.nameFields[0].value}).then(() => {
                this.errorName = null;
                this.loadingName = false;
                this.successName = true;
                this.receiveInfos();


            }).catch(e => {
                this.checkGenericError(e);
                this.errorName = e;
                this.loadingName = false;

            });
        },
        saveCurrency() {
            this.loadingCurrency = true;

            let http = axios.create();
            http.put(this.apiUrl + '/currency', {currency: this.currency}).then(() => {
                this.errorCurrency = null;
                this.loadingCurrency = false;
                this.successCurrency = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorCurrency = e;
                this.loadingCurrency = false;

            });
        },
        checkPaymentInputValidationPrepare() {
            let self = this;
            setTimeout(function () {
                self.checkPaymentInputValidation();
            }, 50);
        },
        checkPaymentInputValidation() {
            let methods = [];
            for (let i = 0, j = this.allowedPaymentMethods.length; i < j; i++) {
                if (this.allowedPaymentMethods[i].value) {
                    methods.push(this.allowedPaymentMethods[i].name);
                }
            }
            if (methods.length === 0) {
                this.errorPaymentMethodsInput = this.$t('x.required.message', {x: this.$t('payment.methods')});
                return;
            }

            methods = [];
            for (let i = 0, j = this.allowedPaymentMethodsGallery.length; i < j; i++) {
                if (this.allowedPaymentMethodsGallery[i].value) {
                    methods.push(this.allowedPaymentMethodsGallery[i].name);
                }
            }
            if (methods.length === 0) {
                this.errorPaymentMethodsInput = this.$t('x.required.message', {x: this.$t('payment.methods')});
                return;
            }

            methods = [];
            for (let i = 0, j = this.allowedPaymentMethodsTerminal.length; i < j; i++) {
                if (this.allowedPaymentMethodsTerminal[i].value) {
                    methods.push(this.allowedPaymentMethodsTerminal[i].name);
                }
            }
            if (methods.length === 0) {
                this.errorPaymentMethodsInput = this.$t('x.required.message', {x: this.$t('payment.methods')});
                return;
            }

            this.errorPaymentMethodsInput = null;
            this.successPaymentMethods = false;
        },
        savePaymentMethods() {
            let methods = [];
            for (let i = 0, j = this.allowedPaymentMethods.length; i < j; i++) {
                if (this.allowedPaymentMethods[i].value) {
                    methods.push(this.allowedPaymentMethods[i].name);
                }
            }
            if (methods.length === 0) {
                this.errorPaymentMethodsInput = this.$t('x.required.message', {x: this.$t('payment.methods')});
                return;
            }

            let methodsGallery = [];
            for (let i = 0, j = this.allowedPaymentMethodsGallery.length; i < j; i++) {
                if (this.allowedPaymentMethodsGallery[i].value) {
                    methodsGallery.push(this.allowedPaymentMethodsGallery[i].name);
                }
            }
            if (methodsGallery.length === 0) {
                this.errorPaymentMethodsInput = this.$t('x.required.message', {x: this.$t('payment.methods')});
                return;
            }

            let methodsTerminal = [];
            for (let i = 0, j = this.allowedPaymentMethodsTerminal.length; i < j; i++) {
                if (this.allowedPaymentMethodsTerminal[i].value) {
                    methodsTerminal.push(this.allowedPaymentMethodsTerminal[i].name);
                }
            }
            if (methodsTerminal.length === 0) {
                this.errorPaymentMethodsInput = this.$t('x.required.message', {x: this.$t('payment.methods')});
                return;
            }

            this.errorPaymentMethodsInput = null;

            this.loadingPaymentMethods = true;
            let http = axios.create();
            http.put(this.apiUrl + '/payment-methods', {
                paymentMethods: methods,
                galleryPaymentMethods: methodsGallery,
                terminalPaymentMethods: methodsTerminal,
            }).then(() => {
                this.errorPaymentMethods = null;
                this.loadingPaymentMethods = false;
                this.successPaymentMethods = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorPaymentMethods = e;
                this.loadingPaymentMethods = false;

            });
        },
        saveShippingGraduatedPrices() {
            this.triggerShippingGraduatedPrices++;

            for (let i = 0, j = this.shippingGraduatedPrices.length; i < j; i++) {
                for (let k = 0, l = this.shippingGraduatedPrices[i].length; k < l; k++) {
                    if (!this.shippingGraduatedPrices[i][k].valid) {
                        return;
                    }
                }
            }

            let parsedGraduatedPrices = [];
            for (let i = 0, j = this.shippingGraduatedPrices.length; i < j; i++) {
                parsedGraduatedPrices.push(
                    {
                        "amount": this.shippingGraduatedPrices[i][0].value,
                        "price": this.shippingGraduatedPrices[i][1].value,
                    }
                );
            }


            this.loadingShippingGraduatedPrices = true;
            let http = axios.create();
            http.put(this.apiUrl + '/shipping-graduated-prices', {shippingGraduatedPrices: parsedGraduatedPrices}).then(() => {
                this.errorShippingGraduatedPrices = null;
                this.loadingShippingGraduatedPrices = false;
                this.successShippingGraduatedPrices = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorShippingGraduatedPrices = e;
                this.loadingShippingGraduatedPrices = false;
                this.successShippingGraduatedPrices = false;

            });
        },
        codeToClipboard() {
            this.copyToClipboard(this.code);
        },
        resetCode() {
            this.codeValue = this.code;
        },
        regenCode() {
            this.codeRegened = true;
            this.genCode(true);
        },
        genCode(closeModal = false) {
            this.loadingCode = true;
            let http = axios.create();
            http.post(this.apiUrl + '/order-token').then((response) => {
                this.code = response.data.token;
                this.codeValue = response.data.token;

                this.errorCode = null;
                this.loadingCode = false;
                this.successCode = true;
                this.receiveSettings();

                if (closeModal) {
                    UIkit.modal("#regen-code").hide();
                }

                setTimeout(function () {
                    let elementId = "code";
                    UIkit.scroll("#" + elementId, {offset: 0}).scrollTo("#" + elementId);
                }, 10);

            }).catch(e => {
                this.checkGenericError(e);
                this.errorCode = e;
                this.loadingCode = false;
                this.successCode = false;

            });
        },
        saveVat() {
            this.triggerVat++;

            for (let i = 0, j = this.vatFields.length; i < j; i++) {
                if (!this.vatFields[i].valid) {
                    return;
                }
            }

            this.loadingVat = true;

            let http = axios.create();
            http.put(this.apiUrl + '/vat', {
                vat: this.vatFields[0].value,
            }).then(() => {
                this.errorVat = null;
                this.loadingVat = false;
                this.successVat = true;
                this.receiveInfos();


            }).catch(e => {
                this.checkGenericError(e);
                this.errorVat = e;
                this.loadingVat = false;
                this.successVat = false;

            });
        },
        saveServiceFee() {
            this.triggerServiceFee++;

            for (let i = 0, j = this.serviceFeeFields.length; i < j; i++) {
                if (!this.serviceFeeFields[i].valid) {
                    return;
                }
            }

            for (let i = 0, j = this.serviceFeeFieldsGallery.length; i < j; i++) {
                if (!this.serviceFeeFieldsGallery[i].valid) {
                    return;
                }
            }

            for (let i = 0, j = this.serviceFeeFieldsTerminal.length; i < j; i++) {
                if (!this.serviceFeeFieldsTerminal[i].valid) {
                    return;
                }
            }

            this.loadingServiceFee = true;

            let discount = null;
            if (this.serviceFeeWithDiscount) {
                discount = this.serviceFeeFields[1].value;
            }

            let galleryDiscount = null;
            if (this.serviceFeeWithDiscountGallery) {
                galleryDiscount = this.serviceFeeFieldsGallery[1].value;
            }

            let terminalDiscount = null;
            if (this.serviceFeeWithDiscountTerminal) {
                terminalDiscount = this.serviceFeeFieldsTerminal[1].value;
            }

            let http = axios.create();
            http.put(this.apiUrl + '/fee', {
                fee: this.serviceFeeFields[0].value,
                discount,

                galleryFee: this.serviceFeeFieldsGallery[0].value,
                galleryDiscount,

                terminalFee: this.serviceFeeFieldsTerminal[0].value,
                terminalDiscount,

            }).then(() => {
                this.errorServiceFee = null;
                this.loadingServiceFee = false;
                this.successServiceFee = true;
                this.receiveInfos();


            }).catch(e => {
                this.checkGenericError(e);
                this.errorServiceFee = e;
                this.loadingServiceFee = false;
                this.successServiceFee = false;

            });
        },
        deleteArticle(withNotification = true) {
            this.loadingDelete = true;

            let http = axios.create();
            http.delete(this.apiUrl + '/articles/' + this.selectedArticle.id).then(() => {
                this.receiveSettings();
                UIkit.modal("#" + this.modalIdArticleDelete).hide();

                if (withNotification) {
                    UIkit.notification({
                        message: this.$t('article.deleted.successfully'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                }

                this.loadingDelete = false;

            }).catch(e => {
                this.checkGenericError(e);
                this.receiveSettings();

                if (withNotification) {
                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                }

                this.loadingDelete = false;
            });
        },
        updateArticle(article, withNotification = false) {
            this.loadingArticles = true;

            let http = axios.create();
            http.put(this.apiUrl + '/articles/' + article.id, article).then(() => {
                this.receiveSettings();
                this.hoveredArticleId = article.id;

                if (withNotification) {
                    UIkit.notification({
                        message: this.$t('x.changes.saved', {x: this.$t('article')}),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                }

            }).catch(e => {
                this.checkGenericError(e);
                this.receiveSettings();

                if (withNotification) {
                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                }

            });
        },
        createArticle() {
            this.triggerCreateArticle++;

            for (let i = 0, j = this.createArticleFields.length; i < j; i++) {
                if (!this.createArticleFields[i].valid) {
                    return;
                }
            }

            if (this.createArticleType === 'orderControl') {
                for (let i = 0, j = this.createArticleFieldsOcId.length; i < j; i++) {
                    if (!this.createArticleFieldsOcId[i].valid) {
                        return;
                    }
                }
            }

            if (this.createArticleType === 'noritsu') {
                for (let i = 0, j = this.createArticleFieldsNoritsu.length; i < j; i++) {
                    if (!this.createArticleFieldsNoritsu[i].valid) {
                        return;
                    }
                }
            }

            // if (this.createArticleVariableLength) {
            for (let i = 0, j = this.createArticleFieldsDPI.length; i < j; i++) {
                if (!this.createArticleFieldsDPI[i].valid) {
                    return;
                }
            }
            // }

            if (this.priceWithMinPrice) {
                for (let i = 0, j = this.createMinPriceFields.length; i < j; i++) {
                    if (!this.createMinPriceFields[i].valid) {
                        return;
                    }
                }
            }

            if (this.priceWithMaxLength) {
                for (let i = 0, j = this.maxLengthCreateFields.length; i < j; i++) {
                    if (!this.maxLengthCreateFields[i].valid) {
                        return;
                    }
                }
            }

            for (let i = 0, j = this.createArticlePaperFields.length; i < j; i++) {
                if (!this.createArticlePaperFields[i].valid) {
                    return;
                }
            }

            for (let i = 0, j = this.createArticleGraduatedPrices.length; i < j; i++) {
                for (let k = 0, l = this.createArticleGraduatedPrices[i].length; k < l; k++) {
                    if (!this.createArticleGraduatedPrices[i][k].valid) {
                        return;
                    }
                }
            }

            let parsedGraduatedPrices = [];
            for (let i = 0, j = this.createArticleGraduatedPrices.length; i < j; i++) {
                parsedGraduatedPrices.push(
                    {
                        "amount": this.createArticleGraduatedPrices[i][0].value,
                        "price": this.createArticleGraduatedPrices[i][1].value,
                    }
                );
            }

            this.loadingArticles = true;

            let http = axios.create();


            let targetPaper = this.createArticlePaperFields[0].value;
            if (this.selectedSuggestedPaper) {
                targetPaper = this.selectedSuggestedPaper;
            }

            let ocId = null;
            if (this.createArticleType === "orderControl") {
                ocId = this.createArticleFieldsOcId[0].value;
            }

            let noritsuID = null;
            if (this.createArticleType === "noritsu") {
                noritsuID = this.createArticleFieldsNoritsu[0].value;
            }

            let active = this.createArticleActive;
            if (this.createArticleType === 'mirage' && (!this.selectedArticle || !this.selectedArticle.mirageFile)) {
                active = false;
            }

            let minPrice = null;
            if (this.priceWithMinPrice) {
                minPrice = this.createMinPriceFields[0].value;
            }

            let maxLength = null;
            if (this.priceWithMaxLength) {
                maxLength = this.maxLengthCreateFields[0].value;
            }

            let articleName = null;
            if (this.settings.modules.includes('articleName') && this.createArticleFieldName[0].value) {
                articleName = this.createArticleFieldName[0].value;
            }

            this.loadingCreateArticle = true;
            if (this.selectedArticle && !this.selectedArticleClone) {
                http.put(this.apiUrl + '/articles/' + this.selectedArticle.id, {
                    "noritsuID": noritsuID,
                    "ocId": ocId,
                    "type": this.createArticleType,
                    "paper": targetPaper,
                    "width": this.createArticleFields[0].value,
                    "height": this.createArticleFields[1].value,
                    "price": this.createPriceFields[0].value,
                    "isHeightVariable": this.createArticleVariableLength,
                    "dpi": this.createArticleFieldsDPI[0].value,
                    "graduatedPrices": parsedGraduatedPrices,
                    "active": active,
                    "isFavorite": this.createArticleFavorite,
                    "minPrice": minPrice,
                    "maxLength": maxLength,
                    "name": articleName,

                    "isTerminal": this.createArticleTerminal,

                }).then(() => {

                    if (this.createArticleType === 'mirage' && (!this.createArticleMirage || this.createArticleMirage.name)) {
                        let formData = new FormData();
                        formData.append("file", this.createArticleMirage);

                        const config = {
                            headers: {
                                'active': this.createArticleActive,
                            },
                        }

                        let http = axios.create();
                        http.put(this.apiUrl + '/articles/' + this.selectedArticle.id + '/mirage-file', formData, config)
                            .then(() => {
                                this.articleUpdateSuccess();

                            })
                            .catch(e => {
                                this.checkGenericError(e);
                                this.errorCreateArticle = e;
                                this.loadingCreateArticle = false;
                                this.successCreateArticle = false;

                            });

                    } else {
                        this.articleUpdateSuccess();

                    }

                }).catch(e => {
                    this.checkGenericError(e);
                    this.errorCreateArticle = e;
                    this.loadingCreateArticle = false;
                    this.successCreateArticle = false;

                });

                return;
            }

            http.post(this.apiUrl + '/articles', {
                "noritsuID": noritsuID,
                "ocId": ocId,
                "type": this.createArticleType,
                "paper": targetPaper,
                "width": this.createArticleFields[0].value,
                "height": this.createArticleFields[1].value,
                "price": this.createPriceFields[0].value,
                "graduatedPrices": parsedGraduatedPrices,
                "active": active,
                "isFavorite": this.createArticleFavorite,
                "isHeightVariable": this.createArticleVariableLength,
                "dpi": this.createArticleFieldsDPI[0].value,
                "minPrice": minPrice,
                "maxLength": maxLength,
                "name": articleName,

                "isTerminal": this.createArticleTerminal,

            }).then(response => {

                if (this.createArticleType === 'mirage' && (!this.createArticleMirage || this.createArticleMirage.name)) {
                    let formData = new FormData();
                    formData.append("file", this.createArticleMirage);

                    const config = {
                        headers: {
                            'active': this.createArticleActive,
                        },
                    }

                    let http = axios.create();
                    http.put(this.apiUrl + '/articles/' + response.data.id + '/mirage-file', formData, config)
                        .then(() => {
                            this.articleCreateSuccess(response);

                        })
                        .catch(e => {
                            this.checkGenericError(e);
                            this.errorCreateArticle = e;
                            this.loadingCreateArticle = false;
                            this.successCreateArticle = false;

                        });

                } else {
                    this.articleCreateSuccess(response);

                }

            }).catch(e => {
                this.checkGenericError(e);
                this.errorCreateArticle = e;
                this.loadingCreateArticle = false;
                this.successCreateArticle = false;

            });
        },
        articleCreateSuccess(response) {
            this.hoveredArticleId = response.data.id;
            UIkit.modal("#" + this.modalIdArticle).hide();

            UIkit.notification({
                message: this.$t(this.selectedArticleClone ? 'article.duplicated.successfully' : 'article.added.successfully'),
                status: 'info',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });

            for (let i = 0, j = this.createArticleFieldsOcId.length; i < j; i++) {
                this.createArticleFieldsOcId[i].value = "";
                this.createArticleFieldsOcId[i].valid = false;
            }

            for (let i = 0, j = this.createArticleFieldsNoritsu.length; i < j; i++) {
                this.createArticleFieldsNoritsu[i].value = "";
                this.createArticleFieldsNoritsu[i].valid = false;
            }

            for (let i = 0, j = this.createArticleFields.length; i < j; i++) {
                this.createArticleFields[i].value = "";
                this.createArticleFields[i].valid = false;
            }

            this.errorCreateArticle = null;
            this.loadingCreateArticle = false;
            this.successCreateArticle = true;
            this.receiveSettings();
        },
        articleUpdateSuccess() {
            this.hoveredArticleId = this.selectedArticle.id;
            UIkit.modal("#" + this.modalIdArticle).hide();

            UIkit.notification({
                message: this.$t('x.changes.saved', {x: this.$t('article')}),
                status: 'info',
                pos: 'top-center',
                timeout: this.notificationTimeout,
            });

            for (let i = 0, j = this.createArticleFields.length; i < j; i++) {
                this.createArticleFields[i].value = "";
                this.createArticleFields[i].valid = false;
            }

            for (let i = 0, j = this.createArticleFieldsOcId.length; i < j; i++) {
                this.createArticleFieldsOcId[i].value = "";
                this.createArticleFieldsOcId[i].valid = false;
            }

            for (let i = 0, j = this.createArticleFieldsNoritsu.length; i < j; i++) {
                this.createArticleFieldsNoritsu[i].value = "";
                this.createArticleFieldsNoritsu[i].valid = false;
            }

            this.errorCreateArticle = null;
            this.loadingCreateArticle = false;
            this.successCreateArticle = true;
            this.receiveSettings();
        },
        savePictures() {
            let objectEntries = Object.entries(this.pictures);
            let count = 0;

            if (objectEntries.length > 0) {
                this.loadingPictures = true;

            } else {
                this.successPictures = true;

            }

            let self = this;
            let timeout = 0;

            for (const [key, value] of objectEntries) {

                setTimeout(function () {
                    if (value) {
                        let formData = new FormData();
                        formData.append("file", value);

                        let http = axios.create();
                        http.put(self.apiUrl + '/pictures/' + key, formData)
                            .then(() => {
                                if (count >= objectEntries.length - 1) {
                                    self.errorPictures = null;
                                    self.loadingPictures = false;
                                    self.successPictures = true;
                                    self.receiveInfos();
                                }

                            })
                            .catch(e => {
                                self.checkGenericError(e);
                                self.errorPictures = e;

                                if (count === objectEntries.length - 1) {
                                    self.loadingPictures = false;
                                    self.successPictures = false;
                                }

                            });

                    } else {
                        let http = axios.create();
                        http.delete(self.apiUrl + '/pictures/' + key).then(() => {
                            if (count >= objectEntries.length - 1) {
                                self.errorPictures = null;
                                self.loadingPictures = false;
                                self.successPictures = true;
                                self.receiveInfos();
                            }

                        }).catch(e => {
                            self.checkGenericError(e);
                            self.errorPictures = e;

                            if (count === objectEntries.length - 1) {
                                self.loadingPictures = false;
                                self.successPictures = false;
                            }

                        });

                    }

                    count++;

                }, timeout);

                timeout += 300
            }
        },
        reset() {
            this.loadingReset = true;

            let http = axios.create();
            http.post(this.apiUrl + '/reset').then(() => {
                this.errorReset = null;
                this.loadingReset = false;
                this.successReset = true;
                this.receiveInfos();

                this.routeTo("setup");

            }).catch(e => {
                this.checkGenericError(e);
                this.errorReset = e;
                this.loadingReset = false;
                this.successReset = false;

            });
        },
        saveEmailSignature() {
            this.loadingEmailSignature = true;

            let http = axios.create();
            http.put(this.apiUrl + '/email-signature', {emailSignature: this.settings.emailSignature}).then(() => {
                this.errorEmailSignature = null;
                this.loadingEmailSignature = false;
                this.successEmailSignature = true;

            }).catch(e => {
                this.checkGenericError(e);
                this.errorEmailSignature = e;
                this.loadingEmailSignature = false;
                this.successEmailSignature = false;

            });
        },
        saveLanguage() {
            this.loadingLanguage = true;

            let http = axios.create();
            http.put(this.apiUrl + '/language', {language: this.settings.language}).then(() => {
                this.errorLanguage = null;
                this.loadingLanguage = false;
                this.successLanguage = true;

            }).catch(e => {
                this.checkGenericError(e);
                this.errorLanguage = e;
                this.loadingLanguage = false;
                this.successLanguage = false;

            });
        },
        saveMandatoryFields() {
            let configurableMandatoryFields = [];
            for (let i = 0, j = this.allowedConfigurableMandatoryFields.length; i < j; i++) {
                if (this.allowedConfigurableMandatoryFields[i].value) {
                    configurableMandatoryFields.push(this.allowedConfigurableMandatoryFields[i].name);
                }
            }

            this.loadingMandatoryFields = true;
            let http = axios.create();
            http.put(this.apiUrl + '/configurable-mandatory-fields', {configurableMandatoryFields: configurableMandatoryFields}).then(() => {
                this.errorMandatoryFields = null;
                this.loadingMandatoryFields = false;
                this.successMandatoryFields = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorMandatoryFields = e;
                this.loadingMandatoryFields = false;
                this.successMandatoryFields = false;

            });
        },
        saveAbsence() {
            let start = null;
            let end = null;

            if (this.absenceActive) {
                start = this.range.start;
                end = this.range.end;
            }

            this.loadingAbsence = true;
            let http = axios.create();
            http.put(this.apiUrl + '/absence', {start: start, end: end}).then(() => {
                this.errorAbsence = null;
                this.loadingAbsence = false;
                this.successAbsence = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorAbsence = e;
                this.loadingAbsence = false;
                this.successAbsence = false;

            });
        },
        saveDataPrivacy() {
            this.loadingDataPrivacy = true;

            let http = axios.create();
            http.put(this.apiUrl + '/data-privacy', {dataPrivacy: this.settings.dataPrivacy}).then(() => {
                this.errorDataPrivacy = null;
                this.loadingDataPrivacy = false;
                this.successDataPrivacy = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorDataPrivacy = e;
                this.loadingDataPrivacy = false;
                this.successDataPrivacy = false;

            });
        },
        triggerInputDialog() {
            document.getElementById('mirage').click();
        },
        triggerInputDialogPicture(key) {
            document.getElementById('filePicture' + key).click();
        },
        deleteMirage() {
            this.createArticleMirage = null;
            this.createArticleActive = false;
        },
        downloadMirage() {
            event.preventDefault();

            if (this.createArticleMirage && this.createArticleMirage.name) {
                let blob = this.createArticleMirage;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = this.getParsedFileName(this.createArticleMirage.name);
                link.click();
                return
            }

            let self = this;
            self.loadingMiragefile = true;

            let request = new XMLHttpRequest();
            request.open("GET", this.apiUrl + '/articles/' + self.selectedArticle.id + '/mirage-file', true);
            request.responseType = "blob";
            request.onload = function () {
                let blob = request.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = self.createArticleMirage;
                link.click();

                self.loadingMiragefile = false;
            };

            request.setRequestHeader("token", VueCookie.get("token"));
            request.send();
        },
        getParsedFileName(fileName) {
            if (fileName.endsWith(".app.zip")) {
                return fileName.replace(".app.zip", ".app");
            }

            return fileName;
        },
        handleFilesUpload() {
            let files = this.$refs['mirage'].files;

            if (files[0].type === "") {
                this.createArticleMirageError = this.$t("invalid.app.file.drop.message");
                return;
            }

            let fileExtension = files[0].name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();

            if (!this.getComputedMirageFileExtensions.includes(fileExtension)) {
                this.createArticleMirageError = this.$t("file.type.error", {
                    name: this.$t('mirage.file'),
                    types: this.getFormattedTypesString(this.getComputedMirageFileExtensions)
                });

                return;
            }

            if (files[0].size > (this.getMaxMirageFileSizeInMB * 1024)) {
                this.createArticleMirageError = this.$t("invalid.mirage.file");
                return;
            }

            this.createArticleMirage = files[0];

            if (!this.selectedArticle || (this.selectedArticle && this.selectedArticle.active)) {
                this.createArticleActive = true;
            }
        },
        saveSiteNotice() {
            this.loadingSiteNotice = true;

            let http = axios.create();
            http.put(this.apiUrl + '/site-notice', {siteNotice: this.settings.siteNotice}).then(() => {
                this.errorSiteNotice = null;
                this.loadingSiteNotice = false;
                this.successSiteNotice = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorSiteNotice = e;
                this.loadingSiteNotice = false;
                this.successSiteNotice = false;

            });
        },
        saveTermsAndConditions() {
            this.loadingTermsAndConditions = true;

            let http = axios.create();
            http.put(this.apiUrl + '/terms-and-conditions', {termsAndConditions: this.settings.termsAndConditions}).then(() => {
                this.errorTermsAndConditions = null;
                this.loadingTermsAndConditions = false;
                this.successTermsAndConditions = true;
                this.receiveInfos();

            }).catch(e => {
                this.checkGenericError(e);
                this.errorTermsAndConditions = e;
                this.loadingTermsAndConditions = false;
                this.successTermsAndConditions = false;

            });
        },
    }
}

</script>

<style lang="scss" scoped>

.file-name {
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cloudConnector-title {
    font-size: 22px;
    font-weight: 500;
}

.system-title {
    font-weight: 500;
    font-size: $site-font-size-large;
}

.width-action {
    min-width: 20px
}

.support-icon {
    text-underline: none !important;
}

.width-input {
    min-width: 80px;
    white-space: nowrap;
}

.max-input {
    max-width: 120px !important;
}

.format-inputs {
    width: 70px !important;
}

.paper-info {
    font-size: 14px;
}

.document-name {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
}

.mirage {
    max-width: 30px;
    max-height: 30px;
}

.mirage-struct {
    width: 100%;
}

.dragover {
    border-color: $info-color;
}

.uk-link {
    text-decoration: none !important;
}

.uk-input, .uk-select {
    height: 50px !important;

    &:hover, &:focus {
        border-color: $info-color !important;
    }
}

.paper-name {
    white-space: nowrap;
    width: 120px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.uk-panel {
    max-height: 280px;
    border: initial !important;
    padding: initial !important;
}

.uk-panel-scrollable {
    height: initial !important;
    resize: none !important;
}

.section-text {
    font-weight: 500;
    margin-bottom: 10px;
}

</style>
