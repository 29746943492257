<template>
    <span
        :uk-tooltip="
            disabled
                ? 'title:' + $t('x.module.disabled.hint', {x: $t(module)})
                : ''
        "
        :class="disabled ? 'module-disabled-struct' : ''">
        <span
            :class="
                disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'
            ">
            <Card
                is-dark
                mode="default"
                :title="$t(title)"
                class="uk-height-1-1">
                <Description v-if="description" :text="$t(description)" />

                <Button :action="newArticle" has-action mode="primary">
                    {{ $t('x.add', {x: $t('file')}) }}
                </Button>

                <div class="uk-margin-medium-top" />

                <div
                    id="sortable-customers"
                    uk-sortable="handle: .uk-sortable-handle">
                    <div v-for="item in files" :id="item.id" :key="item.id">
                        <Card
                            is-dark
                            body-class="uk-card-small"
                            mode="default"
                            class="cursor-pointer uk-margin"
                            uk-toggle="target: #modal-smartsignage-file"
                            v-on:click="selectCustomer(item)">
                            <div class="uk-grid" uk-grid>
                                <div class="uk-flex-middle uk-flex">
                                    <Icon
                                        class="uk-sortable-handle"
                                        :ratio="1.2"
                                        icon="table" />
                                </div>

                                <div>
                                    <video
                                        v-if="item.file.endsWith('mp4')"
                                        :src="item.file"
                                        uk-video="autoplay: inview"
                                        muted
                                        class="customer-logo" />

                                    <img
                                        v-else
                                        :alt="item.file"
                                        :src="item.file"
                                        class="customer-logo" />
                                </div>

                                   <div>
                                Text 1
                                <br />
<br />
                                Text 2
                            </div>
                            </div>


                        </Card>
                    </div>
                </div>

                <div class="uk-margin-medium-top" />

                <Button :action="newArticle" has-action mode="primary">
                    {{ $t('x.add', {x: $t('file')}) }}
                </Button>
            </Card>
        </span>
    </span>
</template>

<script>
import Card from '@/components/generic/Card.vue';
import Description from '@/components/Description.vue';
import Button from '@/components/generic/Button.vue';
import Icon from '@/components/generic/Icon.vue';

export default {
    name: 'SmartSignageFilesForm',
    components: {
        Icon,
        Button,
        Description,
        Card,
    },

    data() {
        return {
            title: 'files',

            files: [
                {
                    id: 1,
                    file: 'https://getuikit.com/docs/images/dark.jpg',
                },
                {
                    id: 2,
                    file: 'https://yootheme.com/site/images/media/yootheme-pro.mp4',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.customer-logo {
    max-width: 200px !important;
    max-height: 120px !important;
}
</style>
