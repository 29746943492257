<template>
    <span
        :uk-tooltip="disabled ? 'title:'+$t('x.module.disabled.hint', {x:$t(module)}) : ''"
        :class="disabled ? 'module-disabled-struct' : ''">
        <span :class="disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'">
            <Card is-dark mode="default" :title="$t(title)" class="uk-height-1-1">
                <Description v-if="description" :text="$t(description)"/>


                <div class="uk-text-center" v-if="true">
                <div class="uk-margin-bottom">Der SmartSignage-Connector ist eingerichtet.</div>


                        <div class="uk-flex uk-flex-center">
                    <div class="blob green"/>
                </div>

                    <div>Letzte Aktivität vor 1 min.</div>


                </div>





                <hr class="uk-hr"/>

                 <div class="uk-child-width-auto uk-grid-match uk-flex-center" uk-grid>

                                <div v-for="download in connectors" :key="download.name"
                                     class="uk-text-center">
                                    <a :href="download.link">
                                        <RoundButton
                                            icon="cloud-download"/>
                                    </a>
                                    <div class="uk-margin-small-top system-title uk-link-reset">
                                        <a :href="download.link">
                                            {{ download.name }}
                                        </a>
                                    </div>
                                </div>
                             </div>

                <div v-if="false">
                 <a :href="getMainOS().link">
                     <Button :text="$t('download.for.x', {x: getMainOS().name})"
                             has-action
                             is-large
                             is-max
                             mode="primary"
                     />
                 </a>

                <div v-if="getOtherOS() && getOtherOS().name"
                     class="uk-margin-small-top">
                    <Translate translation-key="not.your.os"/> <a :href="getOtherOS().link"><Translate
                    translation-key="download.for.x" :parameter="{x:getOtherOS().name}"/></a>
                </div>
                    </div>



                  <div class="uk-margin-medium-top"/>
                                    <span v-if="isRegistered || codeRegened">
                                        <ToggleItem :is-disabled="code !== null"
                                                    :toggle="'target: #'+module+'-regen-code'">
                                            <Button :text="$t('regen.setup.code')"
                                                    :is-disabled="code !== null"
                                                    is-large is-max mode="danger"/>
                                        </ToggleItem>

                                        <Modal :id="module+'-regen-code'" :title="$t('regen.setup.code')">
                                            <div class="uk-margin-medium-bottom uk-text-center"
                                                 v-html="$t(module+'.regen.setup.code.warning')"/>

                                            <div v-if="!successReset" class="uk-flex uk-flex-center" uk-grid>
                                                <div>
                                                    <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                                            is-max is-min-width
                                                            mode="default"/>
                                                </div>
                                                <div>
                                                    <Button :action="regenCode" :is-loading="loadingCode"
                                                            :text="$t('regen')"
                                                            has-action
                                                            is-large is-max
                                                            is-min-width mode="danger"/>
                                                </div>
                                            </div>
                                        </Modal>
                                    </span>

                                    <Button
                                        v-else
                                        :text="$t('gen.setup.code')"
                                        is-large
                                        :action="genCode" has-action
                                        :is-loading="loadingCode"
                                        is-max is-min-width
                                        :is-disabled="code !== null"
                                        mode="success"/>

                                    <div v-if="errorCode" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorCode"/>


                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <div v-if="successCode" class="uk-animation-fade" id="code">
                                        <TextField icon="code"
                                                   classes="cursor-pointer"
                                                   name="setup-code"
                                                   label="setup.code"
                                                   :tooltip="$t('copy.to.clipboard')"
                                                   v-model="codeValue"
                                                   :on-click="codeToClipboard"
                                                   :on-change="resetCode"
                                                   :action-function="codeToClipboard"
                                                   action-icon="copy-clipboard"
                                                   :action-tooltip="$t('copy.to.clipboard')"/>
                                    </div>

                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage
                                        :message="$t('gen.setup.code.success')"
                                        :success="successCode"/>




            </Card>
        </span>
    </span>

</template>


<script>

import Card from "@/components/generic/Card.vue";
import Button from "@/components/generic/Button.vue";
import Translate from "@/components/Translate.vue";
import Description from "@/components/Description.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import TextField from "@/components/generic/TextField.vue";
import Modal from "@/components/generic/Modal.vue";
import ToggleItem from "@/components/generic/ToggleItem.vue";
import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import axios from "axios";
import UIkit from "uikit";
import RoundButton from "@/components/RoundButton.vue";

export default {
    name: 'SmartSignageConnectorForm',
    components: {
        RoundButton,
        SuccessMessage,
        ToggleItem,
        Modal,
        TextField,
        ErrorMessage,
        Description, Translate, Button, Card
    },
    props: {
        connectors: Array,
        disabled: Boolean,
        isRegistered: Boolean,
    },
    data() {
        return {
            module: "smartSignage",
            title: "smartSignage.connector",
            description: "smartSignage.connector.description",

            code: null,
            codeValue: null,
            codeRegened: false,
            errorCode: null,
            loadingCode: false,
            successCode: false,

            successReset: false,
        }
    },
    methods: {
        genCode2(closeModal = false) {
            this.loadingCode = true;
            let http = axios.create();
            http.post(this.apiUrl + '/smartsignage-token').then((response) => {
                this.code = response.data.token;
                this.codeValue = response.data.token;

                this.errorCode = null;
                this.loadingCode = false;
                this.successCode = true;

                this.$emit('created');

                if (closeModal) {
                    UIkit.modal("#" + this.module + "-regen-code").hide();
                }

                setTimeout(function () {
                    let elementId = "code";
                    UIkit.scroll("#" + elementId, {offset: 15}).scrollTo("#" + elementId);
                }, 10);

            }).catch(e => {
                this.checkGenericError(e);
                this.errorCode = e;
                this.loadingCode = false;
                this.successCode = false;

            });
        },
        codeToClipboard() {
            this.copyToClipboard(this.code);
        },
        resetCode() {
            this.codeValue = this.code;
        },
        regenCode2() {
            this.codeRegened = true;
            this.genCode(true);
        },

    },
}

</script>


<style lang="scss" scoped>

.system-title {
    font-weight: 500;
    font-size: $site-font-size-large;
}


.blob {
    background: black;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 30px;
    width: 30px;
    transform: scale(1);
    animation: pulse-black 2s infinite;

    &.grey {
        animation: initial;
    }
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.blob.white {
    background: white;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.blob.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.blob.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
    animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}

.blob.yellow {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}

.blob.blue {
    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
}

.blob.green {
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}

.blob.purple {
    background: rgba(142, 68, 173, 1);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
    animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
    }
}


.blob.grey {
    background: grey;
    box-shadow: 0 0 0 0 rgb(133, 127, 127);
}
</style>
