<template>
    <span
        :class="disabled ? 'module-disabled-struct' : ''"
        :uk-tooltip="
            disabled
                ? 'title:' +
                  $t('x.module.disabled.hint', {x: $t('smartSignage')})
                : ''
        ">
        <span
            :class="
                disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'
            ">
            <div
                class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match"
                uk-grid>
                <div
                    v-if="!settings.smartSignageLicensedUntil"
                    class="uk-width-1-1">
                    <InfoBox mode="primary" icon="info">
                        {{
                            $t('x.module.disabled.message', {
                                x: $t('smartSignage'),
                            })
                        }}
                    </InfoBox>
                </div>
                <div v-else class="">
                    <License
                        :license-link="settings.licenseLink"
                        :licensed-until="settings.smartSignageLicensedUntil"
                        :description="$t('smartSignage')" />
                </div>

                <div>
                    <SmartSignageConnectorForm
                        @created="receiveSettings"
                        :is-registered="true"
                        :connectors="settings.smartSignageConnectors"
                        :disabled="disabled" />
                </div>

                <div class="uk-width-1-1">
                    <SmartSignageFilesForm />
                </div>
            </div>
        </span>
    </span>
</template>

<script>
import License from '@/components/License.vue';
import InfoBox from '@/components/generic/InfoBox.vue';
import SmartSignageConnectorForm from '@/components/forms/SmartSignagelConnectorForm.vue';
import SmartSignageFilesForm from '@/components/forms/SmartSignageFilesForm.vue';

export default {
    name: 'SmartSignageForm',
    components: {
        SmartSignageFilesForm,
        SmartSignageConnectorForm,
        InfoBox,
        License,
    },
    props: {
        disabled: Boolean,
        settings: Object,
    },
};
</script>

<style lang="scss" scoped></style>
